import React from 'react';
import {Col, Row} from "antd";

function JobseekerSg() {
    return (
        <div className="megaMenu">
            <Row gutter={30}>
                <Col md={6} xl={4}>
                    <h3>Your Career</h3>
                    <ul>
                        <li><a href="https://m.hays.com.sg/it/search" target="_blank" rel="noreferrer">Search jobs</a></li>
                        <li><a href="https://m.hays.com.sg/it/speculativeCV" target="_blank" rel="noreferrer">Submit my CV</a></li>
                        <li><a href="https://hays.ramcoes.com/rvw" target="_blank" rel="noreferrer">Timesheets</a></li>
                        <li><a href="https://www.hays.com.sg/it/it-salary-check-singapore" target="_blank" rel="noreferrer">My salary</a></li>
                    </ul>
                </Col>
                <Col md={8} xl={6}>
                    <h3>Technology Expertise</h3>
                    <ul>
                        <li><a href="https://www.hays.com.sg/it/cloud-computing-jobs" target="_blank" rel="noreferrer">Cloud Computing</a></li>
                        <li><a href="https://www.hays.com.sg/it/cyber-security-jobs" target="_blank" rel="noreferrer">Cyber Security</a></li>
                        <li><a href="https://www.hays.com.sg/it/data-analytics-jobs" target="_blank" rel="noreferrer">Data & Advanced Analytics</a></li>
                        <li><a href="https://www.hays.com.sg/it/it-infrastructure-jobs" target="_blank" rel="noreferrer">Infrastructure</a></li>
                        <li><a href="https://www.hays.com.sg/it/it-projects-change-management-jobs" target="_blank" rel="noreferrer">Projects & Change Management</a></li>
                        <li><a href="https://www.hays.com.sg/it/software-development-jobs" target="_blank" rel="noreferrer">Software Development</a></li>
                        <li><a href="https://www.hays.com.sg/it/it-technology-jobs" target="_blank" rel="noreferrer">Technology</a></li>
                    </ul>
                </Col>
            </Row>
        </div>
    )
}

export default JobseekerSg;