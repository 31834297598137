export const RESTART        = 'RESTART';
export const GOTO_PAGE      = 'GOTO_PAGE';
export const SET_DEBUG      = 'DEBUG';
export const SHOW_WECHAT_MODAL = 'SHOW_WECHAT_MODAL';
export const HIDE_WECHAT_MODAL = 'HIDE_WECHAT_MODAL';
export const NEXT_QUESTION  = 'NEXT_QUESTION';
export const SET_QUESTIONS  = 'SET_QUESTIONS';
export const SET_LANGUAGES  = 'SET_LANGUAGES';
export const SET_LOC        = 'SET_LOCATION';
export const PAGE_START     = "start-page";
export const PAGE_INTRO     = "intro-page";
export const PAGE_QUESTION  = "question-page";
export const PAGE_END       = "end-page";
export const PAGE_ANSWER    = "answer-page";
