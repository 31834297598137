import React from 'react';
import {Menu} from "antd";

function NavJpMobile() {
    const dropdownJobseeker = [
        {
            key: '1',
            label: (
                <>求職者様 <span className="arrow-down-icon"></span></>
            ),
            children: [
                {
                    type: 'group',
                    label: '転職',
                    children: [
                        {
                            label: <a href="https://m.hays.co.jp/it/search" target="_blank" rel="noreferrer">IT求人を探す</a>,
                            key: 'yc1',
                        },
                        {
                            label: (<a href="https://m.hays.co.jp/it/speculativeCV" target="_blank" rel="noreferrer">職務経歴書を提出する</a>),
                            key: 'yc2',
                        },
                        {
                            label: (<a href="https://www8.hays.co.jp/HaysOnlineJapan/Login.aspx" target="_blank" rel="noreferrer">タイムシート</a>),
                            key: 'yc3',
                        },
                        {
                            label: (<a href="https://www.hays.co.jp/en/salary-check" target="_blank" rel="noreferrer">年収査定</a>),
                            key: 'yc4',
                        },
                    ],
                },
                {
                    type: 'group',
                    label: '専門分野',
                    children: [
                        {
                            label: (<a href="https://www.hays.co.jp/it/cloud-computing-jobs" target="_blank" rel="noreferrer">クラウドコンピューティング</a>),
                            key: 'te1',
                        },
                        {
                            label: (<a href="https://www.hays.co.jp/it/cyber-security-jobs" target="_blank" rel="noreferrer">サイバーセキュリティ</a>),
                            key: 'te2',
                        },
                        {
                            label: (<a href="https://www.hays.co.jp/it/data-analytics-jobs" target="_blank" rel="noreferrer">データ・アドバンストアナリティクス</a>),
                            key: 'te3',
                        },
                        {
                            label: (<a href="https://www.hays.co.jp/it/it-infrastructure-jobs" target="_blank" rel="noreferrer">インフラストラクチャー</a>),
                            key: 'te4',
                        },
                        {
                            label: (<a href="https://www.hays.co.jp/it/it-projects-change-management-jobs" target="_blank" rel="noreferrer">プロジェクトマネージメント・ チェンジマネージメント（PM)</a>),
                            key: 'te5',
                        },
                        {
                            label: (<a href="https://www.hays.co.jp/it/software-development-jobs" target="_blank" rel="noreferrer">ソフトウェア開発</a>),
                            key: 'te6',
                        },
                        {
                            label: (<a href="https://www.hays.co.jp/it/it-technology-jobs" target="_blank" rel="noreferrer">テクノロジー</a>),
                            key: 'te7',
                        }
                    ],
                },
            ],
        },
    ];

    const dropdownEmployer = [
        {
            key: '1',
            label: (
                <>採用企業様 <span className="arrow-down-icon"></span></>
            ),
            children: [
                {
                    type: 'group',
                    label: 'サービス一覧',
                    children: [
                        {
                            label: <a href="https://www.hays.co.jp/it/register-a-vacancy" target="_blank" rel="noreferrer">求人を登録する</a>,
                            key: 'yc1',
                        },
                        {
                            label: (<a href="https://www8.hays.co.jp/HaysOnlineJapan/Login.aspx" target="_blank" rel="noreferrer">タイムシート</a>),
                            key: 'yc2',
                        },
                        {
                            label: (<a href="https://www.hays.co.jp/salary-guide" target="_blank" rel="noreferrer">ヘイズアジア給与ガイド</a>),
                            key: 'yc3',
                        },
                    ],
                },
                {
                    type: 'group',
                    label: '専門分野',
                    children: [
                        {
                            label: (<a href="https://www.hays.co.jp/it/cloud-computing-recruitment" target="_blank" rel="noreferrer">クラウドコンピューティング</a>),
                            key: 'te1',
                        },
                        {
                            label: (<a href="https://www.hays.co.jp/it/cyber-security-recruitment" target="_blank" rel="noreferrer">サイバーセキュリティ</a>),
                            key: 'te2',
                        },
                        {
                            label: (<a href="https://www.hays.co.jp/it/data-analytics-recruitment" target="_blank" rel="noreferrer">データ・アドバンストアナリティクス</a>),
                            key: 'te3',
                        },
                        {
                            label: (<a href="https://www.hays.co.jp/it/it-infrastructure-recruitment" target="_blank" rel="noreferrer">インフラストラクチャー</a>),
                            key: 'te4',
                        },
                        {
                            label: (<a href="https://www.hays.co.jp/it/it-projects-change-management-recruitment" target="_blank" rel="noreferrer">プロジェクトマネージメント・ チェンジマネージメント（PM)</a>),
                            key: 'te5',
                        },
                    ],
                },
                {
                    type: 'group',
                    label: 'サービス紹介',
                    children: [
                        {
                            label: <a href="https://www.hays.co.jp/it/it-recruitment" target="_blank" rel="noreferrer">正社員・契約社員求人のご紹介</a>,
                            key: 's1',
                        },
                        {
                            label: (<a href="https://www.hays.co.jp/it/msp-recruitment" target="_blank" rel="noreferrer">人材管理サービス（MSP）</a>),
                            key: 's2',
                        },
                        {
                            label: (<a href="https://www.hays.co.jp/it/rpo-recruitment" target="_blank" rel="noreferrer">採用アウトソーシング（RPO）</a>),
                            key: 's3',
                        },
                    ],
                }
            ],
        },
    ];

    return (
        <ul>
            <li className="first">
                <a href="https://www.hays.co.jp/it/about-us" target="_blank" rel="noreferrer">IT転職・採用支援について</a>
            </li>
            <li className="first">
                <Menu mode="inline" items={dropdownJobseeker} />
            </li>
            <li className="first">
                <Menu mode="inline" items={dropdownEmployer} />
            </li>
            <li className="first">
                <a href="https://www.hays.co.jp/join-hays" target="_blank" rel="noreferrer">自社採用</a>
            </li>
            <li className="first">
                <a href="https://m.hays.co.jp/it/office-locator" target="_blank" rel="noreferrer">お問い合わせ</a>
            </li>
            <li className="first">
                <a href="https://www.haysplc.com/investors" target="_blank" rel="noreferrer">投資家</a>
            </li>
        </ul>
    )
}

export default NavJpMobile;