import React from 'react';
import {useTranslation} from "react-i18next";
import CountUp from 'react-countup';
import i18n from "i18next";

function Percentage({ percentage }) {
    const { t } = useTranslation();

    return (
        <>
            {percentage && i18n.language === "en" &&
                <h1><CountUp duration={0.2} end={percentage} />% {t('question.match')}</h1>
            }
            {percentage && (i18n.language === "jp" || i18n.language === "cn") &&
                <h1>{t('question.match')}<CountUp duration={0.2} end={percentage} />%</h1>
            }
            {!percentage &&
                <h2>{t('question.nomatch')}</h2>
            }
        </>
    )
}

export default Percentage;
