import React from 'react';
import {Col, Row} from "antd";

function EmployerCn() {
    return (
        <div className="megaMenu">
            <Row gutter={30}>
                <Col md={6} xl={5}>
                    <h3>你的团队</h3>
                    <ul>
                        <li><a href="https://www.hays-china.cn/web/hays-cn-technology/register-a-vacancy" target="_blank" rel="noreferrer">登记职位空缺</a></li>
                        <li><a href="https://www.hays-china.cn/web/hays-cn-technology/benchmark-salaries1" target="_blank" rel="noreferrer">薪酬基准</a></li>
                    </ul>
                </Col>
                <Col md={8} xl={6}>
                    <h3>科技专业知识</h3>
                    <ul>
                        <li><a href="https://www.hays-china.cn/web/hays-cn-technology/cloud-computing-recruitment" target="_blank" rel="noreferrer">云计算</a></li>
                        <li><a href="https://www.hays-china.cn/web/hays-cn-technology/cyber-security-recruitment" target="_blank" rel="noreferrer">网络安全</a></li>
                        <li><a href="https://www.hays-china.cn/web/hays-cn-technology/data-analytics-recruitment" target="_blank" rel="noreferrer">数据分析与建模</a></li>
                        <li><a href="https://www.hays-china.cn/web/hays-cn-technology/it-infrastructure-recruitment" target="_blank" rel="noreferrer">基础设施</a></li>
                        <li><a href="https://www.hays-china.cn/web/hays-cn-technology/it-projects-change-management-recruitment" target="_blank" rel="noreferrer">项目&变革管理</a></li>
                        <li><a href="https://www.hays-china.cn/web/hays-cn-technology/software-development-recruitment" target="_blank" rel="noreferrer">软件开发</a></li>
                    </ul>
                </Col>
                <Col md={8} xl={6}>
                    <h3>服务</h3>
                    <ul>
                        <li><a href="https://www.hays-china.cn/web/hays-cn-technology/it-recruitment" target="_blank" rel="noreferrer">长期职位招聘</a></li>
                        <li><a href="https://www.hays-china.cn/web/hays-cn-technology/msp-recruitment" target="_blank" rel="noreferrer">管理服务方案</a></li>
                        <li><a href="https://www.hays-china.cn/web/hays-cn-technology/rpo-recruitment" target="_blank" rel="noreferrer">招聘流程外包</a></li>
                    </ul>
                </Col>
            </Row>
        </div>
    )
}

export default EmployerCn;