import React, {useContext, useEffect, useState} from 'react';
import "./PageEnd.scss";
import {useTranslation} from "react-i18next";
import eeYes1 from "./assets/ee-yes-1.png";
import eeYes2 from "./assets/ee-yes-2.png";
import eeNo from "./assets/ee-no.png";
import erYes from "./assets/er-yes.png";
import erNo from "./assets/er-no.png";
import {Context} from "../../app/store";
import fb from "./assets/fb.png";
import linkedIn from "./assets/in.png";
import twitter from "./assets/tw.png";
import wechat from "./assets/wechat.png";
import ig from "./assets/ig.png";
import weibo from "./assets/weibo.png";
import {Col, Image, message, Row} from "antd";
import axios from "axios";
import {API_ROTATORIES} from "../../constants";
import i18n from "i18next";
import ImgPlaceholder from "../Common/Image/ImgPlaceholder";
import {SHOW_WECHAT_MODAL} from "../../app/types";


function PageEnd() {
    const { t } = useTranslation();
    const [globalState, dispatch] = useContext(Context);
    const [rotatories, setRotatories] = useState(null);
    const queryParams = new URLSearchParams(window.location.search);


    let dt = queryParams.get("date");
    if(!dt){
        dt = "";
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        getRotatories()
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const getRotatories = () => {

        axios.get(API_ROTATORIES + "/" + i18n.language + "/" + globalState.location + "/" + globalState.type, {
                params: {
                    date: dt
                }
            })
            .then(response => {
                setRotatories(response.data.payload);
            })
            .catch(error => {
                message.error("Error retrieving data from the server.")
            })
    }

    return (
        <div className={'page-end-wrapper'}>
            <div className="thank-you">
                <div className="yes-no-result">
                    <div className="yes-no-result-text">
                        <div className="yes-no-result-text-inner">
                            {t('end.majority')}
                            {globalState.location === "jp" && globalState.answertype === "yes" &&
                                <>
                                    YESでした
                                </>
                            }
                            {globalState.location === "jp" && globalState.answertype === "no" &&
                                <>
                                    NOでしたね！
                                </>
                            }
                            {globalState.location !== "jp" &&
                                <>
                                <h1>{globalState.answertype === 'yes' ? t('end.yes') : t('end.no')}</h1>
                                </>
                            }
                        </div>
                    </div>
                    <div className={`yes-no-result-img ${globalState.type.toLowerCase() !== 'employer' && globalState.answertype === 'yes' ? 'ee-yes' : ''}`}>
                        {globalState.type.toLowerCase() === 'employer' &&
                            <div className="img-wrap">
                                {globalState.answertype === 'yes' &&
                                    <Image src={erYes} width={352} alt="" rootClassName={"erYes"} fallback={ImgPlaceholder} preview={false} />
                                }
                                {globalState.answertype !== 'yes' &&
                                    <Image src={erNo} width={283} alt="" rootClassName={"erNo"} fallback={ImgPlaceholder} preview={false} />
                                }
                            </div>
                        }
                        {globalState.type.toLowerCase() !== 'employer' &&
                            <div className="img-wrap">
                                {globalState.answertype === 'yes' &&
                                    <>
                                        <Image src={eeYes1} width={80} alt="" rootClassName={"eeYes1"} fallback={ImgPlaceholder} preview={false} />
                                        <Image src={eeYes2} width={177} alt="" rootClassName={"eeYes2"} fallback={ImgPlaceholder} preview={false} />
                                    </>
                                }
                                {globalState.answertype !== 'yes' &&
                                    <Image src={eeNo} width={253} alt="" rootClassName={"eeNo"} fallback={ImgPlaceholder} preview={false} />
                                }
                            </div>
                        }
                    </div>
                    <div className="triangle-right" />
                </div>
                <div className="thank-you-text">
                    {globalState.type.toLowerCase() === 'employer' &&
                        <>
                            {globalState.answertype === 'yes' &&
                                <>
                                    <h2 className={'employer'}>{t('end.employer.yes.title')}</h2>
                                    <p className={'employer'}>{t('end.employer.yes.desc')}</p>
                                </>
                            }
                            {globalState.answertype !== 'yes' &&
                                <>
                                    <h2 className={'employer'}>{t('end.employer.no.title')}</h2>
                                    <p className={'employer'}>{t('end.employer.no.desc')}</p>
                                </>
                            }
                            <div className="thank-you-btn er">
                                {globalState.location === "sg" &&
                                    <>
                                        <a id={"end-search-" + globalState.location + "-" + globalState.type}
                                            className="btn" href="https://www.hays.com.sg/it/register-a-vacancy"
                                           target={"_blank"} rel={"noreferrer"}>{t('end.employer.cta')}</a>
                                        <a id={"end-salary-guide-" + globalState.location + "-" + globalState.type}
                                            className="btn search" href="https://www.hays.com.sg/salary-guide" target={"_blank"} rel={"noreferrer"}>{t('end.employer.cta2')}</a>
                                    </>
                                }
                                {globalState.location === "hk" &&
                                    <>
                                        <a id={"end-search-" + globalState.location + "-" + globalState.type}
                                            className="btn" href="https://www.hays.com.hk/it/register-a-vacancy"
                                           target={"_blank"} rel={"noreferrer"}>{t('end.employer.cta')}</a>
                                        <a id={"end-salary-guide-" + globalState.location + "-" + globalState.type}
                                            className="btn search" href="https://www.hays.com.hk/it/technology-salary-guide"
                                           target={"_blank"} rel={"noreferrer"}>Download 2022 Hays Greater China Technology Salary Guide</a>
                                    </>
                                }
                                {globalState.location === "my" &&
                                    <>
                                        <a id={"end-search-" + globalState.location + "-" + globalState.type}
                                            className="btn" href="https://www.hays.com.my/it/register-a-vacancy"
                                           target={"_blank"} rel={"noreferrer"}>{t('end.employer.cta')}</a>
                                        <a id={"end-salary-guide-" + globalState.location + "-" + globalState.type}
                                            className="btn search" href="https://www.hays.com.my/salary-guide" target={"_blank"} rel={"noreferrer"}>{t('end.employer.cta2')}</a>
                                    </>
                                }
                                {globalState.location === "jp" &&
                                    <>
                                        <a id={"end-search-" + globalState.location + "-" + globalState.type}
                                            className="btn" href="https://www.hays.co.jp/it/register-a-vacancy"
                                           target={"_blank"} rel={"noreferrer"}>{t('end.employer.cta')}</a>
                                        <a id={"end-salary-guide-" + globalState.location + "-" + globalState.type}
                                            className="btn search" href="https://www.hays.co.jp/salary-guide"
                                            target={"_blank"} rel={"noreferrer"}>{t('end.employer.cta2')}</a>
                                    </>
                                }
                                {globalState.location === "cn" &&
                                    <>
                                        <a id={"end-search-" + globalState.location + "-" + globalState.type}
                                            className="btn" href="https://www.hays-china.cn/it/register-a-vacancy"
                                           target={"_blank"} rel={"noreferrer"}>{t('end.employer.cta')}</a>
                                        <a id={"end-salary-guide-" + globalState.location + "-" + globalState.type}
                                            className="btn search" href="https://www.hays-china.cn/it/technology-salary-guide"
                                            target={"_blank"} rel={"noreferrer"}>{t('end.employer.cta2')}</a>
                                    </>
                                }
                            </div>
                        </>

                    }
                    {globalState.type.toLowerCase() !== 'employer' &&
                        <>
                            {globalState.answertype === 'yes' &&
                                <>
                                    <h2 className={'employee'}>{t('end.employee.yes.title')}</h2>
                                    <p className={'employee'}>{t('end.employee.yes.desc')}</p>
                                </>
                            }
                            {globalState.answertype !== 'yes' &&
                                <>
                                    <h2 className={'employee'}>{t('end.employee.no.title')}</h2>
                                    <p className={'employee'}>{t('end.employee.no.desc')}</p>
                                </>
                            }
                            <div className="thank-you-btn">
                                {globalState.location === "sg" &&
                                <>
                                    <a id={"end-search-" + globalState.location + "-" + globalState.type}
                                        className="btn search" href="https://m.hays.com.sg/it/search" rel={"noreferrer"} target={"_blank"}>{t('end.employee.cta')}</a>
                                    <a id={"submit-cv-" + globalState.location + "-" + globalState.type}
                                        className="btn"
                                       href="https://m.hays.com.sg/it/speculativeCV"
                                       rel={"noreferrer"} target={"_blank"}>{t('end.employee.cta2')}</a>

                                    <a id={"salary-check-" + globalState.location + "-" + globalState.type}
                                        className="btn salary" href="https://www.hays.com.sg/it/it-salary-check-singapore" rel={"noreferrer"} target={"_blank"}>{t('end.employee.cta3')}</a>
                                </>
                                }
                                {globalState.location === "hk" &&
                                    <>
                                        <a id={"end-search-" + globalState.location + "-" + globalState.type}
                                            className="btn search" href="https://m.hays.com.hk/it/search" rel={"noreferrer"} target={"_blank"}>{t('end.employee.cta')}</a>
                                        <a id={"submit-cv-" + globalState.location + "-" + globalState.type}
                                            className="btn"
                                           href="https://m.hays.com.hk/it/speculativeCV/"
                                           rel={"noreferrer"} target={"_blank"}>{t('end.employee.cta2')}</a>

                                        <a id={"salary-check-" + globalState.location + "-" + globalState.type}
                                            className="btn salary" href="https://www.hays.com.hk/it/it-salary-check-hongkongsar" rel={"noreferrer"} target={"_blank"}>{t('end.employee.cta3')}</a>
                                    </>
                                }
                                {globalState.location === "jp" &&
                                    <>
                                        <a id={"end-search-" + globalState.location + "-" + globalState.type}
                                            className="btn search" href="https://m.hays.co.jp/it/search" rel={"noreferrer"} target={"_blank"}>{t('end.employee.cta')}</a>
                                        <a id={"submit-cv-" + globalState.location + "-" + globalState.type}
                                            className="btn"
                                           href="https://m.hays.co.jp/it/speculativeCV"
                                           rel={"noreferrer"} target={"_blank"}>{t('end.employee.cta2')}</a>

                                        <a id={"salary-check-" + globalState.location + "-" + globalState.type}
                                            className="btn salary" href="https://www.hays.co.jp/salary-check" rel={"noreferrer"} target={"_blank"}>{t('end.employee.cta3')}</a>
                                    </>
                                }
                                {globalState.location === "my" &&
                                    <>
                                        <a id={"end-search-" + globalState.location + "-" + globalState.type}
                                            className="btn search" href="https://m.hays.com.my/it/search" rel={"noreferrer"} target={"_blank"}>{t('end.employee.cta')}</a>
                                        <a id={"submit-cv-" + globalState.location + "-" + globalState.type}
                                            className="btn"
                                           href="https://m.hays.com.my/it/speculativeCV"
                                           rel={"noreferrer"} target={"_blank"}>{t('end.employee.cta2')}</a>

                                        <a id={"salary-check-" + globalState.location + "-" + globalState.type}
                                            className="btn salary" href="https://www.hays.com.my/it/it-salary-check-malaysia" rel={"noreferrer"} target={"_blank"}>{t('end.employee.cta3')}</a>
                                    </>
                                }
                                {globalState.location === "cn" &&
                                <>
                                    <a id={"end-search-" + globalState.location + "-" + globalState.type}
                                        className="btn search" href="https://m.hays-china.cn/it/search" rel={"noreferrer"} target={"_blank"}>{t('end.employee.cta')}</a>
                                    <a id={"submit-cv-" + globalState.location + "-" + globalState.type}
                                        className="btn"
                                       href="https://m.hays-china.cn/it/speculativeCV"
                                       rel={"noreferrer"} target={"_blank"}>{t('end.employee.cta2')}</a>

                                    <a id={"salary-check-" + globalState.location + "-" + globalState.type}
                                        className="btn salary" href="https://www.hays-china.cn/salary-check" rel={"noreferrer"} target={"_blank"}>{t('end.employee.cta3')}</a>
                                </>
                            }

                            </div>
                        </>
                    }
                </div>
            </div>

            <div className={'share-this'}>
                <p>{t('end.sharethis')}</p>
                {globalState.location === "sg" &&
                    <>
                        {/*<a target={"_blank"} rel={"noreferrer"} className="share-this__icon" href="https://www.twitter.com"><img src={twitter} width={37} height={37} alt=""/></a>*/}
                        <a id={"end-linkedin-" + globalState.location + "-" + globalState.type}
                            target={"_blank"} rel={"noreferrer"} className="share-this__icon linkedin" href="https://www.linkedin.com/company/hays/"><img src={linkedIn} width={37} height={37} alt=""/></a>
                        {/*<a target={"_blank"} rel={"noreferrer"}className="share-this__icon fb" href="https://www.facebook.com"><img src={fb} width={37} height={37} alt=""/></a>*/}
                    </>
                }
                {globalState.location === "my" &&
                    <>
                        {/*<a target={"_blank"} rel={"noreferrer"} className="share-this__icon" href="https://www.twitter.com"><img src={twitter} width={37} height={37} alt=""/></a>*/}
                        <a id={"end-linkedin-" + globalState.location + "-" + globalState.type}
                            target={"_blank"} rel={"noreferrer"} className="share-this__icon linkedin" href="https://www.linkedin.com/company/hays/"><img src={linkedIn} width={37} height={37} alt=""/></a>
                        <a id={"end-facebook-" + globalState.location + "-" + globalState.type}
                            target={"_blank"} rel={"noreferrer"} className="share-this__icon fb" href="https://www.facebook.com/HaysMY"><img src={fb} width={37} height={37} alt=""/></a>
                    </>
                }
                {globalState.location === "hk" &&
                    <>
                        <a id={"end-linkedin-" + globalState.location + "-" + globalState.type}
                            target={"_blank"} rel={"noreferrer"} className="share-this__icon linkedin" href="https://www.linkedin.com/company/hays/"><img src={linkedIn} width={37} height={37} alt=""/></a>
                        <a id={"end-facebook-" + globalState.location + "-" + globalState.type}
                            target={"_blank"} rel={"noreferrer"} className="share-this__icon fb" href="https://www.facebook.com/HaysCareersHongKong/"><img src={fb} width={37} height={37} alt=""/></a>
                        <a id={"end-instagram-" + globalState.location + "-" + globalState.type}
                            target={"_blank"} rel={"noreferrer"} className="share-this__icon fb" href="https://www.instagram.com/hays_hongkong/"><img src={ig} width={37} height={37} alt=""/></a>
                    </>
                }
                {globalState.location === "jp" &&
                    <>
                        <a id={"end-twitter-" + globalState.location + "-" + globalState.type}
                            target={"_blank"} rel={"noreferrer"} className="share-this__icon" href="https://twitter.com/haysjapan"><img src={twitter} width={37} height={37} alt=""/></a>
                        <a id={"end-linkedin-" + globalState.location + "-" + globalState.type}
                           target={"_blank"} rel={"noreferrer"} className="share-this__icon linkedin" href="https://www.linkedin.com/company/hays/?originalSubdomain=jp"><img src={linkedIn} width={37} height={37} alt=""/></a>
                        <a id={"end-facebook-" + globalState.location + "-" + globalState.type}
                            target={"_blank"} rel={"noreferrer"} className="share-this__icon fb" href="https://www.facebook.com/haysjapan"><img src={fb} width={37} height={37} alt=""/></a>
                    </>
                }
                {globalState.location === "cn" &&
                    <>
                        <a id={"end-weibo-" + globalState.location + "-" + globalState.type}
                            target={"_blank"} rel={"noreferrer"} className="share-this__icon weibo" href="https://www.weibo.com/hayschina"><img src={weibo} width={37} height={37} alt=""/></a>
                        <a id={"end-linkedin-" + globalState.location + "-" + globalState.type}
                            target={"_blank"} rel={"noreferrer"} className="share-this__icon linkedin" href="https://www.linkedin.com/company/hays"><img src={linkedIn} width={37} height={37} alt=""/></a>
                        <a id={"end-wechat-" + globalState.location + "-" + globalState.type}
                            rel={"noreferrer"} className="share-this__icon wechat"  onClick={()=>{    dispatch({
                            type: SHOW_WECHAT_MODAL,
                        });return false; } }><img src={wechat} width={37} height={37} alt=""/></a>
                    </>
                }


            </div>
            {rotatories &&
                <Row gutter={30}>
                    {rotatories.map(rotatory => {
                        return (
                            <Col xs={24} xl={12} key={rotatory.id}>
                                <div className="article-item">
                                    <div className="text-article" data-id={rotatory.id}>
                                        <a id={"article-text-" + globalState.location + "-" + globalState.type + "-" + rotatory.id}
                                            href={rotatory.link} target={"_blank"} rel={"noreferrer"}>
                                            <p>{rotatory.title}</p>
                                            {rotatory.content}
                                        </a>
                                    </div>
                                    <div className="img-article">
                                        <a id={"article-img-" + globalState.location + "-" + globalState.type + "-" + rotatory.id}
                                            href={rotatory.link} target={"_blank"} rel={"noreferrer"}>
                                            <Image src={rotatory.image} alt={rotatory.title} fallback={ImgPlaceholder} preview={false} />
                                        </a>
                                    </div>
                                </div>
                            </Col>
                        );
                    })}
                </Row>
            }
        </div>
    )
}

export default PageEnd;
