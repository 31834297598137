import React, {useContext, useEffect, useState} from 'react';
import {Context} from "../../app/store";
import "./PageStart.scss";
import {Alert, Button, Col, Image, message, Row, Select, Spin} from "antd";
import {GOTO_PAGE, PAGE_QUESTION, SET_QUESTIONS} from "../../app/types";
import {API_INITIALS, API_QUESTIONS} from "../../constants";
import axios from "axios";
import {useTranslation} from 'react-i18next';
import foodImg from "./assets/food.png";
import sportsImg from "./assets/sports.png";
import dreamImg from "./assets/dream.png";
import apparelImg from "./assets/brand.png";
import {CaretRightOutlined} from "@ant-design/icons";
import i18n from "i18next";
import options_destination from "./destinations.json";
import options_food from "./food.json";
import options_brand from "./brand.json";
import options_sport from "./sport.json";
import bgDesc from "./assets/img-start-mobile.jpg";
import ImgPlaceholder from "../Common/Image/ImgPlaceholder";

function PageStart() {

    const [globalState, dispatch] = useContext(Context);
    const [letsgoClass, setLetsgoClass] = useState(true);
    const [answers, setAnswers] = useState({sports: null, destination: null, brand: null, food: null})
    const Option = Select.Option;
    const {t} = useTranslation();
    const initialState = {
        error: null,
        loading: false
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {
        getQuestions()
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const getQuestions = () => {

        axios.get(API_QUESTIONS + "/" + i18n.language)
            .then(response => {
                let questions = response.data.payload;
                dispatch({type: SET_QUESTIONS, payload: {questions: questions}})
            })
            .catch(error => {
                message.error("Error retrieving data from the server.")
            })
    }

    useEffect(() => {
        if(answers.food && answers.sports && answers.brand && answers.destination){
            setLetsgoClass(false);
        }
    }, [answers]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleSportChange = value => {
        if (value === "") value = null;
        setAnswers({
            ...answers,
            sports: value
        })
    };
    const handleBrandChange = value => {
        if (value === "") value = null;
        setAnswers({
            ...answers,
            brand: value
        })
    };
    const handleDestinationChange = value => {
        if (value === "") value = null;
        setAnswers({
            ...answers,
            destination: value
        })
    };
    const handleFoodChange = value => {
        if (value === "") value = null;
        setAnswers({
            ...answers,
            food: value
        })
    };
    const letsGo = () => {
        if (!state.loading && answers.food !== null && answers.sports !== null && answers.brand !== null && answers.destination !== null) {
            setState({
                ...state,
                loading: true
            })

            let payload = {
                "languageIso": i18n.language,
                "role" : globalState.type,
                "sessionId": globalState.sessionId,
                "marketId": globalState.location,
                "responses": [
                    {
                        "question": t('start.questions.title1'),
                        "answer": answers.sports
                    },
                    {
                        "question": t('start.questions.title2'),
                        "answer": answers.destination
                    },
                    {
                        "question": t('start.questions.title3'),
                        "answer": answers.brand
                    },
                    {
                        "question": t('start.questions.title4'),
                        "answer": answers.food
                    }
                ],


            };

            axios.post(API_INITIALS, payload)
                .then(response => {

                    // let result = response.data.payload;

                    setState({
                        ...state,
                        loading: false
                    })
                    dispatch({
                        type: GOTO_PAGE,
                        payload: {
                            page: PAGE_QUESTION,
                            sessionId: globalState.sessionId,
                            progress: 2,

                        },
                    });
                })
                .catch(error => {
                    let msg = error?.response?.data?.statusCode?.msg
                    setState({
                        ...state,
                        error: msg ?? null,
                        loading: false
                    })
                })
        } else {

        }

    };

    const sportOptions = options_sport[globalState.type][globalState.location].map((option, i) =>
        <Option key={i+1} value={option}>{option}</Option>
    );
    const destinationOptions = options_destination[globalState.type][globalState.location].map((option, i) =>
        <Option key={i+1} value={option}>{option}</Option>
    );
    const brandOptions = options_brand[globalState.type][globalState.location].map((option, i) =>
        <Option key={i+1} value={option}>{option}</Option>
    );
    const foodOptions = options_food[globalState.type][globalState.location].map((option, i) =>
        <Option key={i+1} value={option}>{option}</Option>
    );

    return (
        <div className='page-wrapper page-start-wrapper'>
            <div className="start-desc">
                <p>{t('start.description')}</p>
                <Image src={bgDesc} rootClassName={"mobile"} fallback={ImgPlaceholder} preview={false} />
            </div>
            <div className="select-questions">
                <Row className="select-questions-items" gutter={{ xs: 0, sm: 30, md: 30, lg: 30}}>
                    <Col xs={24} sm={12} md={12} lg={6}>
                        <div className="q-item sports">
                            {t('start.questions.title1')}
                            <Image src={sportsImg} alt={t('start.questions.title1')} width={154} height={72} fallback={ImgPlaceholder} preview={false} />
                            <Select placement={"bottomLeft"} placeholder={t('start.questions.select')} onChange={handleSportChange} size="large" bordered={false} showArrow={false} dropdownClassName="custom-dropdown sports-dropdown">
                                {sportOptions}
                            </Select>
                        </div>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={6}>
                        <div className="q-item dream">
                            {t('start.questions.title2')}
                            <Image src={dreamImg} alt={t('start.questions.title2')} width={139} height={91} fallback={ImgPlaceholder} preview={false} />
                            <Select placement={"bottomLeft"} placeholder={t('start.questions.select')} onChange={handleDestinationChange} size="large" bordered={false} showArrow={false} dropdownClassName="custom-dropdown dream-dropdown">
                                {destinationOptions}
                            </Select>
                        </div>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={6}>
                        <div className="q-item apparel">
                            {t('start.questions.title3')}
                            <Image src={apparelImg} alt={t('start.questions.title3')} width={121} height={92} fallback={ImgPlaceholder} preview={false} />
                            <Select placement={"bottomLeft"} placeholder={t('start.questions.select')} onChange={handleBrandChange} size="large" bordered={false} showArrow={false} dropdownClassName="custom-dropdown apparel-dropdown">
                                {brandOptions}
                            </Select>
                        </div>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={6}>
                        <div className="q-item food">
                            {t('start.questions.title4')}
                            <Image src={foodImg} alt={t('start.questions.title4')} width={95} height={87} fallback={ImgPlaceholder} preview={false} />
                            <Select placement={"bottomLeft"} placeholder={t('start.questions.select')} onChange={handleFoodChange} size="large" bordered={false} showArrow={false} dropdownClassName="custom-dropdown food-dropdown">
                                {foodOptions}
                            </Select>
                        </div>
                    </Col>
                </Row>

                    <Button id={"start-" + globalState.location + "-" + globalState.type} onClick={() => letsGo()} className={`btn lets-go-btn ${letsgoClass ? 'disabled' : ''}`} disabled={letsgoClass}>
                        {state.loading && <Spin />}
                        {!state.loading &&
                            <>
                                {t('start.cta')} <CaretRightOutlined/>
                            </>
                        }
                    </Button>

                {state.error &&
                    <Alert message={state.error} type="error" showIcon/>
                }
            </div>
        </div>
    )
}

export default PageStart;
