/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */

import React, {useContext, useState} from 'react';
import "./Header.scss";
import {Header} from "antd/es/layout/layout";
import {Context} from "../../../app/store";
import logo from "./assets/layout_set_logo.jpg"
import searchLogo from "./assets/search.png"
import {Row} from "antd";
import ProgressBar from "./ProgressBar";
import {useTranslation} from "react-i18next";
import NavSg from "./nav/NavSg";
import NavCn from "./nav/NavCn";
import NavMy from "./nav/NavMy";
import NavHk from "./nav/NavHk";
import NavJp from "./nav/NavJp";
import weibo from "../../PageEnd/assets/weibo.png";
import wechatqr from "../../PageEnd/assets/wechat-qr.png";
import {HIDE_WECHAT_MODAL, SHOW_WECHAT_MODAL} from "../../../app/types";



function HaysHeader() {
    const { t } = useTranslation();
    const [globalState, dispatch] = useContext(Context);

    const [search, setSearch] = useState("");

    const performSearch = (event) => {
        if (event != null) {
            event.preventDefault();
        }
        if (globalState.location === "sg") {
            window.location.replace('https://www.hays.com.sg/it/site-search?searchParameters=' + search);
        }
        if (globalState.location === "cn") {
            window.location.replace('https://www.hays-china.cn/it/site-search?searchParameters=' + search);
        }
        if (globalState.location === "my") {
            window.location.replace('https://www.hays.com.my/it/site-search?searchParameters=' + search);
        }
        if (globalState.location === "hk") {
            window.location.replace('https://www.hays.com.hk/it/site-search?searchParameters=' + search);
        }
        if (globalState.location === "jp") {
            window.location.replace('https://www.hays.co.jp/it/site-search?searchParameters=' + search);
        }
    }

    return (
        <Header className={"hays-header"}>
            <div className={'debug'}>
                { globalState.debug &&
                    <div>Market: {globalState.location}
                        <span> - </span>
                         Type: { globalState.type }
                    </div>
                }
            </div>
            <Row className="language-row" justify={"end"}>

            </Row>
            <div className="header-main-wrapper">
                <Row className="row" id="header-main">
                    <div className="logo-wrapper">
                        {globalState.location === "sg" &&
                            <a className="logo custom-logo" href="https://www.hays.com.sg/it/" target="_blank" rel="noreferrer">
                                <img alt="Hays" src={logo}/>
                            </a>
                        }
                        {globalState.location === "my" &&
                            <a className="logo custom-logo" href="https://www.hays.com.my/it/" target="_blank" rel="noreferrer">
                                <img alt="Hays" src={logo}/>
                            </a>
                        }
                        {globalState.location === "hk" &&
                            <a className="logo custom-logo" href="https://www.hays.com.hk/it" target="_blank" rel="noreferrer">
                                <img alt="Hays" src={logo}/>
                            </a>
                        }
                        {globalState.location === "cn" &&
                            <a className="logo custom-logo" href="https://www.hays-china.cn/it" target="_blank" rel="noreferrer">
                                <img alt="Hays" src={logo}/>
                            </a>
                        }
                        {globalState.location === "jp" &&
                            <a className="logo custom-logo" href="https://www.hays.co.jp/it/" target="_blank" rel="noreferrer">
                                <img alt="Hays" src={logo}/>
                            </a>
                        }
                    </div>
                    <div className="right-nav">
                        <div className="search-wrapper">
                            <div id="site-search">
                                <form
                                    className="hays_form"
                                    id="site_search"
                                    onSubmit={(event) => performSearch(event)}
                                >
                                    <input type="text"
                                           placeholder="Site search"
                                           value={search}
                                           onChange={(x) => setSearch(x.currentTarget.value)}/>
                                    <button type="button" onClick={() => performSearch()} className="icon-search">
                                        <img alt="Search" src={searchLogo}/>
                                    </button>
                                </form>
                            </div>
                        </div>
                        <nav id="nav-social">
                            {globalState.location === "sg" &&
                                <ul>
                                    <li><a href="https://www.linkedin.com/company/hays/" id={"linkedin-" + globalState.location} className="icon icon-30 icon-linkedin" rel="noreferrer" target="_blank"/></li>
                                    {/*<li><a href="https://twitter.com/hays_us" rel="noreferrer" className="icon icon-30 icon-twitter" target="_blank"/></li>*/}
                                    {/*<li><a href="https://www.facebook.com/HaysRecruitmentUS/" rel="noreferrer" className="icon icon-30 icon-facebook" target="_blank"/></li>*/}
                                </ul>
                            }
                            {globalState.location === "my" &&
                                <ul>
                                    <li><a href="https://www.linkedin.com/company/hays/" id={"linkedin-" + globalState.location} className="icon icon-30 icon-linkedin" rel="noreferrer" target="_blank"/></li>
                                    {/*<li><a href="https://twitter.com/hays_us" rel="noreferrer" className="icon icon-30 icon-twitter" target="_blank"/></li>*/}
                                    <li><a href="https://www.facebook.com/HaysMY" id={"facebook-" + globalState.location} rel="noreferrer" className="icon icon-30 icon-facebook" target="_blank"/></li>
                                </ul>
                            }
                            {globalState.location === "hk" &&
                                <ul>
                                    <li><a href="https://www.linkedin.com/company/hays" id={"linkedin-" + globalState.location} className="icon icon-30 icon-linkedin" rel="noreferrer" target="_blank"/></li>
                                    <li><a target={"_blank"} rel={"noreferrer"} id={"instagram-" + globalState.location} className="icon icon-30 icon-insta" href="https://www.instagram.com/hays_hongkong/" /></li>
                                    <li><a href="https://www.facebook.com/HaysCareersHongKong/" id={"facebook-" + globalState.location} rel="noreferrer" className="icon icon-30 icon-facebook" target="_blank"/></li>
                                </ul>
                            }
                            {globalState.location === "cn" &&
                                <>
                                <ul>
                                    <li><a href="https://www.linkedin.com/company/hays" id={"linkedin-" + globalState.location} className="icon icon-30 icon-linkedin" rel="noreferrer" target="_blank"/></li>
                                    <li><a target={"_blank"} rel={"noreferrer"} id={"weibo-" + globalState.location} className="icon icon-30" href="https://www.weibo.com/hayschina"><img src={weibo} width={20} height={20} style={{margin: "0 1px"}} alt=""/></a></li>
                                    <li><a onClick={()=>{    dispatch({
                                        type: SHOW_WECHAT_MODAL,
                                    });return false; } } rel="noreferrer" id={"wechat-" + globalState.location} className="icon icon-30 icon-wechat" target="_blank"/></li>
                                </ul>

                                {globalState.showModal &&
                                    <div className={"wchat-modal-container"} onClick={()=>{    dispatch({
                                        type: HIDE_WECHAT_MODAL,
                                    }); }}>
                                    <div className={"wechat-modal"}>
                                    <img src={wechatqr} alt={'wechat'}/>
                                    </div>
                                    </div>
                                }
                                </>
                            }
                            {globalState.location === "jp" &&
                                <ul>
                                    <li><a href="https://www.linkedin.com/company/hays/?originalSubdomain=jp" id={"linkedin-" + globalState.location} className="icon icon-30 icon-linkedin" rel="noreferrer" target="_blank"/></li>
                                    <li><a href="https://twitter.com/HaysJapan" rel="noreferrer" id={"twitter-" + globalState.location} className="icon icon-30 icon-twitter" target="_blank"/></li>
                                    <li><a href="https://www.facebook.com/haysjapan/" id={"facebook-" + globalState.location} rel="noreferrer" className="icon icon-30 icon-facebook" target="_blank"/></li>
                                </ul>
                            }

                        </nav>
                    </div>
                </Row>
            </div>

            <nav id="nav-main">
                {globalState.location === "sg" && <NavSg/>}
                {globalState.location === "cn" && <NavCn/>}
                {globalState.location === "my" && <NavMy/>}
                {globalState.location === "hk" && <NavHk/>}
                {globalState.location === "jp" && <NavJp/>}
            </nav>

            <div className="separator" />

            <div className="main-title">
                <h1>{t('title')}</h1>
                <div className="green-separator"><ProgressBar/></div>
            </div>
        </Header>
    );
}

export default HaysHeader;