import React from 'react';
import {Col, Row} from "antd";

function EmployerSg() {
    return (
        <div className="megaMenu">
            <Row gutter={30}>
                <Col md={6} xl={5}>
                    <h3>Your Team</h3>
                    <ul>
                        <li><a href="https://www.hays.com.sg/it/register-a-vacancy" target="_blank" rel="noreferrer">Register a vacancy</a></li>
                        <li><a href="https://hays.ramcoes.com/rvw" target="_blank" rel="noreferrer">Timesheets</a></li>
                        <li><a href="https://www.hays.com.sg/salary-guide" target="_blank" rel="noreferrer">Benchmark salaries</a></li>
                    </ul>
                </Col>
                <Col md={8} xl={6}>
                    <h3>Technology Expertise</h3>
                    <ul>
                        <li><a href="https://www.hays.com.sg/it/cloud-computing-recruitment" target="_blank" rel="noreferrer">Cloud Computing</a></li>
                        <li><a href="https://www.hays.com.sg/it/cyber-security-recruitment" target="_blank" rel="noreferrer">Cyber Security</a></li>
                        <li><a href="https://www.hays.com.sg/it/data-analytics-recruitment" target="_blank" rel="noreferrer">Data & Advanced Analytics</a></li>
                        <li><a href="https://www.hays.com.sg/it/it-infrastructure-recruitment" target="_blank" rel="noreferrer">Infrastructure</a></li>
                        <li><a href="https://www.hays.com.sg/it/it-projects-change-management-recruitment" target="_blank" rel="noreferrer">Projects & Change Management</a></li>
                        <li><a href="https://www.hays.com.sg/it/software-development-recruitment" target="_blank" rel="noreferrer">Software Development</a></li>
                    </ul>
                </Col>
                <Col md={8} xl={6}>
                    <h3>Service</h3>
                    <ul>
                        <li><a href="https://www.hays.com.sg/it/it-recruitment" target="_blank" rel="noreferrer">Permanent & Contract Recruitment</a></li>
                        <li><a href="https://www.hays.com.sg/it/msp-recruitment" target="_blank" rel="noreferrer">Managed Service Programmes (MSP)</a></li>
                        <li><a href="https://www.hays.com.sg/it/rpo-recruitment" target="_blank" rel="noreferrer">Recruitment Process Outsourcing (RPO)</a></li>
                    </ul>
                </Col>
            </Row>
        </div>
    )
}

export default EmployerSg;