import React, {useContext, useEffect} from 'react';
import "./PageIntro.scss";
import {useTranslation} from 'react-i18next';
import {GOTO_PAGE, PAGE_START, SET_DEBUG, SET_LOC} from "../../app/types";
import {Context} from "../../app/store";
import {CaretRightOutlined} from "@ant-design/icons";
import {Button, Image} from "antd";
import IntroImage from "./assets/bg-intro.jpg";
import i18n from "../../i18n";
import ImgPlaceholder from "../Common/Image/ImgPlaceholder";

function PageIntro() {

    const { t } = useTranslation();
    const [globalState, dispatch] = useContext(Context);
    const queryParams = new URLSearchParams(window.location.search)

    useEffect(() => {


        let market = window.location.pathname.replaceAll("/", "");
        let debug = queryParams.get("debug");

        if(debug === "true"){
            dispatch({
                type: SET_DEBUG,
                payload: {
                },
            });
        }

        let hostname = window.location.hostname;
        if(hostname.includes(".co.jp")){
            market = "jp";
        }
        if(hostname.includes(".cn")){
            market = "cn";
        }
        if(hostname.includes(".com.sg")){
            market = "sg";
        }
        if(hostname.includes(".com.my")){
            market = "my";
        }
        if(hostname.includes(".com.hk")){
            market = "hk";
        }

        if(market && (market === "sg" || market === "my" || market === "jp" || market === "cn" || market === "hk")){
        }else{
            market = globalState.location;
        }
        let defLang = "en";
        switch (market) {
            case "sg":
                defLang = "en";
                break;
            case "my":
                defLang = "en";
                break;
            case "hk":
                defLang = "en";
                break;
            case "cn":
                defLang = "cn";
                break;
            case "jp":
                defLang = "jp";
                break;
            default:
                defLang = "en";
                break;
        }


        if(globalState.userChangedLang === false) {
            i18n.init({lng: defLang, fallbackLng: defLang});
        }

        dispatch({
            type: SET_LOC,
            payload: {
                location: market
            }
        })

    }, [])// eslint-disable-line react-hooks/exhaustive-deps
    const goStart = (type) => {

        let uuid = require("uuid");
        let id = uuid.v4();
            dispatch({
                type: GOTO_PAGE,
                payload: {
                    page: PAGE_START,
                    type: type,
                    sessionId: id,
                    progress: 1
                },
            });
    }

    return (
        <div className={`page-wrapper page-info-wrapper lang-${i18n.language}`}>
            <div className="text-body">
                <p>{t('intro.body')}</p>
            </div>
            <div className="infographic-container">
                <div className="choose">
                    <Image src={IntroImage} rootClassName={"mobile"} alt="Jobseeker and Employer" fallback={ImgPlaceholder} preview={false} />

                    <div className="triangle">
                        <div className="triangle-text"><p>{t('intro.triangle')}</p></div>
                    </div>

                    <div className="actions">
                        <div className="employer">
                            <Button id={"employer-" + globalState.location} onClick={() => goStart('Employer')} className="btn">{t('intro.employers')} <CaretRightOutlined /></Button>
                        </div>
                        <div className="jobseeker">
                            <Button id={"jobseeker-" + globalState.location} onClick={() => goStart('Employee')} className="btn">{t('intro.jobseekers')} <CaretRightOutlined /></Button>
                        </div>
                    </div>
                </div>
                <div className="info info1">
                    <div className="info1-top">{t('intro.flexible')}</div>
                    <div className={"author desktop"}>{t('intro.flexible2')}</div>
                    <div className={"author mobile"}>{t('intro.flexible2_mobile')}</div>
                </div>
                <div className="info info2">
                    <div className="house"></div>
                    <span className={"flexible3"}>{t('intro.flexible3')}</span>
                </div>
            </div>
        </div>
    )
}

export default PageIntro;
