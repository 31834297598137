import React from 'react';
import {Col, Row} from "antd";

function JobseekerJp() {
    return (
        <div className="megaMenu">
            <Row gutter={30}>
                <Col md={6} xl={4}>
                    <h3>転職</h3>
                    <ul>
                        <li><a href="https://m.hays.co.jp/it/search" target="_blank" rel="noreferrer">IT求人を探す</a></li>
                        <li><a href="https://m.hays.co.jp/it/speculativeCV" target="_blank" rel="noreferrer">職務経歴書を提出する</a></li>
                        <li><a href="https://www8.hays.co.jp/HaysOnlineJapan/Login.aspx" target="_blank" rel="noreferrer">タイムシート</a></li>
                        <li><a href="https://www.hays.co.jp/en/salary-check" target="_blank" rel="noreferrer">年収査定</a></li>
                    </ul>
                </Col>
                <Col md={8} xl={6}>
                    <h3>専門分野</h3>
                    <ul>
                        <li><a href="https://www.hays.co.jp/it/cloud-computing-jobs" target="_blank" rel="noreferrer">クラウドコンピューティング</a></li>
                        <li><a href="https://www.hays.co.jp/it/cyber-security-jobs" target="_blank" rel="noreferrer">サイバーセキュリティ</a></li>
                        <li><a href="https://www.hays.co.jp/it/data-analytics-jobs" target="_blank" rel="noreferrer">データ・アドバンストアナリティクス</a></li>
                        <li><a href="https://www.hays.co.jp/it/it-infrastructure-jobs" target="_blank" rel="noreferrer">インフラストラクチャー</a></li>
                        <li><a href="https://www.hays.co.jp/it/it-projects-change-management-jobs" target="_blank" rel="noreferrer">プロジェクトマネージメント・ チェンジマネージメント（PM)</a></li>
                        <li><a href="https://www.hays.co.jp/it/software-development-jobs" target="_blank" rel="noreferrer">ソフトウェア開発</a></li>
                        <li><a href="https://www.hays.co.jp/it/it-technology-jobs" target="_blank" rel="noreferrer">テクノロジー</a></li>
                    </ul>
                </Col>
            </Row>
        </div>
    )
}

export default JobseekerJp;