import React from 'react';
import "./AnimatedChart.scss";
import "./AnimatedChartHk.scss";

function AnimatedChartHk() {
    return (
        <>
            <div className="pie pie-bottom-hk"></div>
            <div className="pie pie-left-hk"></div>
            <div className="pie pie-right-hk"></div>
        </>
    );
}
export default AnimatedChartHk;