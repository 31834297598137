import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";
import "./AnalyticsRight.scss";
import resumeEmployer from "./assets/resume/resume-employer-right.png";
import resumeEmployee1 from "./assets/resume/resume-employees-right-1.png";
import resumeEmployee2 from "./assets/ee/ee-1-2.png";
import ee21 from "./assets/ee/ee-2-1.png";
import er21 from "./assets/er/er-2-1.png";
import er22 from "./assets/er/er-2-2.png";
import er23 from "./assets/er/er-2-3.png";
import er24 from "./assets/er/er-2-4.png";
import er31 from "./assets/er/er-3-1.png";
import er4 from "./assets/er/er-4.png";
import ee4 from "./assets/ee/ee-4.png";
import er5 from "./assets/er/er-5-new.png";
import ee51 from "./assets/ee/ee-5-1.png";
import ee52 from "./assets/ee/ee-5-2.png";
import {Context} from "../../app/store";
import {Image} from "antd";
import ImgPlaceholder from "../Common/Image/ImgPlaceholder";


function AnalyticsRight({ order, type }) {
    const { t } = useTranslation();
    const [globalState] = useContext(Context);
    return (
        <>
            {order === 1 &&
                <div className={`analytics-right__content ${type === 'employer' ? 'er-1' : 'ee-1'}`}>
                    <div className="em-text">
                        <span>{type === 'employer' ? 70 : 79}%</span><br/>
                        {type === 'employer' ? t('question.resume.employer.feels') : t('question.resume.employee.of')}
                    </div>
                    {type === 'employer' &&
                        <Image src={resumeEmployer} width={225} alt="" fallback={ImgPlaceholder} preview={false} />
                    }
                    {type !== 'employer' &&
                        <>
                            <Image src={resumeEmployee1} width={217} alt="" rootClassName={"img-1"} fallback={ImgPlaceholder} preview={false} />
                            <Image src={resumeEmployee2} width={176} alt="" rootClassName={"img-2"} fallback={ImgPlaceholder} preview={false} />
                        </>
                    }
                </div>
            }
            {order === 2 &&
                <div className={`analytics-right__content ${type === 'employer' ? 'er-2' : 'ee-2'}`}>
                    {type !== 'employer' &&
                        <>
                            <div className="em-text">
                                <div className="per">61%</div>
                                {t('question.workplace.employee.of')}
                            </div>
                            <Image src={ee21} width={240} alt="" fallback={ImgPlaceholder} preview={false}/>
                        </>
                    }
                    {type === 'employer' &&
                        <>
                            <div className="er-item">
                                <Image src={er21} width={106} alt="" rootClassName={"absolute clock"} fallback={ImgPlaceholder} preview={false} />
                                <Image src={er23} width={126} alt="" fallback={ImgPlaceholder} preview={false} />
                                <div className="em-text">
                                    <span>59%</span><br/>
                                    {t('question.workplace.employer.would1')}
                                </div>
                            </div>
                            <div className="er-item">
                                <Image src={er22} width={138} alt="" rootClassName={"absolute calendar"} fallback={ImgPlaceholder} preview={false} />
                                <Image src={er24} width={126} alt="" fallback={ImgPlaceholder} preview={false} />
                                <div className="em-text">
                                    <span>28%</span><br/>
                                    {t('question.workplace.employer.would2')}
                                </div>
                            </div>
                        </>
                    }
                </div>
            }
            {order === 3 &&
                <div className={`analytics-right__content ${type === 'employer' ? 'er-3' : 'ee-3'}`}>
                    {type === 'employer' &&
                        <>
                            <div className="em-text">
                                <span>43%</span><br/>
                                {t('question.salary.employer.the')}
                            </div>
                            <Image src={er31} width={282} alt="" fallback={ImgPlaceholder} preview={false} />
                        </>
                    }
                    {type !== 'employer' &&
                        <>
                            <div className="em-text">
                                {t('question.salary.employee.pay')}

                                {globalState.location === "sg" && <>{t('question.salary.employee.sg')}</> }
                                {globalState.location === "my" && <>{t('question.salary.employee.my')}</> }
                                {globalState.location === "hk" && <>{t('question.salary.employee.hk')}</> }

                            </div>
                            <div className="em-col-wrap">
                                <div className="em-col-item">
                                    {globalState.location === "sg" && <span>46%</span> }
                                    {globalState.location === "my" && <span>32%</span> }
                                    {globalState.location === "hk" && <span>41%</span> }
                                    {globalState.location === "jp" && <span>47%</span> }
                                    {globalState.location === "cn" && <span>44%</span> }

                                    <div className="em-col first">
                                        {globalState.location === "cn" && <>的雇主预计涨薪在3%到6%之间</> }
                                        {globalState.location === "jp" && <>3% アップ</> }

                                        {globalState.location === "my" &&
                                            <>
                                                {t('question.salary.employee.increase.from')} 3% {t('question.salary.employee.to')} 6%
                                            </>
                                        }

                                        {globalState.location === "sg" &&
                                            <>
                                                {t('question.salary.employee.increase.up')} 3%
                                            </>
                                        }
                                        {globalState.location === "hk" &&
                                            <>
                                                {t('question.salary.employee.increase.up')} 3%
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="em-col-item">
                                    {globalState.location === "sg" && <span>29%</span> }
                                    {globalState.location === "my" && <span>28%</span> }
                                    {globalState.location === "hk" && <span>25%</span> }
                                    {globalState.location === "jp" && <span>25%</span> }
                                    {globalState.location === "cn" && <span>17%</span> }
                                    <div className="em-col second">
                                        {globalState.location === "cn" && <>的雇主预计涨薪在6%到10%之间</> }
                                        {globalState.location === "jp" && <>6% アップ</> }

                                        {globalState.location === "my" &&
                                            <>
                                                {t('question.salary.employee.increase.up')} 3%
                                            </>
                                        }

                                        {globalState.location === "sg" &&
                                            <>
                                                {t('question.salary.employee.increase.from')} 3% {t('question.salary.employee.to')} 6%
                                            </>
                                        }
                                        {globalState.location === "hk" &&
                                            <>
                                                {t('question.salary.employee.increase.from')} 3% {t('question.salary.employee.to')} 6%
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            }
            {order === 4 &&
                <div className={`analytics-right__content ${type === 'employer' ? 'er-4' : 'ee-4'}`}>
                    {type === 'employer' &&
                        <>
                            <Image src={er4} width={330} alt="" fallback={ImgPlaceholder} preview={false} />
                            <div className="em-text">
                                <span>54%</span><br/>
                                {t('question.culture.employer.said')}
                            </div>
                        </>
                    }
                    {type !== 'employer' &&
                        <>
                            <div className="em-text">
                                <div className="item">
                                    <div className="per">54%</div>{t('question.culture.employee.wants')}
                                </div>
                                <div className="item">
                                    <div className="per">34%</div>{t('question.culture.employee.of')}
                                </div>
                            </div>
                            <Image src={ee4} width={234} alt="" fallback={ImgPlaceholder} preview={false} />
                        </>
                    }
                </div>
            }
            {order === 5 &&
                <>
                    <div className={`analytics-right__content ${type === 'employer' ? 'er-5' : 'ee-5'}`}>
                        {type === 'employer' &&
                            <>
                                <Image src={er5} width={139} alt="" fallback={ImgPlaceholder} preview={false} />
                                <div className="em-text">
                                    <div className="item">
                                        <div className="per">
                                            {globalState.location === "sg" && <>37%</> }
                                            {globalState.location === "my" && <>37%</> }
                                            {globalState.location === "hk" && <>37%</> }
                                            {globalState.location === "jp" && <>37%</> }
                                            {globalState.location === "cn" && <>37%</> }
                                        </div>
                                        <p>{t('question.tech.employer.of')}</p>
                                    </div>
                                </div>
                            </>
                        }
                        {type !== 'employer' &&
                            <>
                                <div className="em-text">
                                    <div className="per">27%</div>
                                    {t('question.tech.employee.of')}
                                </div>
                                <Image src={ee51} width={85} alt="" rootClassName={"ee51"} fallback={ImgPlaceholder} preview={false} />
                                <Image src={ee52} width={237} alt="" rootClassName={"ee52"} fallback={ImgPlaceholder} preview={false} />
                            </>
                        }
                    </div>
                </>
            }
        </>
    )
}

export default AnalyticsRight;