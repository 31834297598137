import React from 'react';
import {Col, Row} from "antd";

function JobseekerCn() {
    return (
        <div className="megaMenu">
            <Row gutter={30}>
                <Col md={6} xl={4}>
                    <h3>你的职业发展</h3>
                    <ul>
                        <li><a href="https://m.hays-china.cn/it/search" target="_blank" rel="noreferrer">寻找信息技术类职位</a></li>
                        <li><a href="https://m.hays-china.cn/it/speculativeCV" target="_blank" rel="noreferrer">提交我的简历</a></li>
                        <li><a href="https://www.hays-china.cn/web/hays-cn-technology/my-salary" target="_blank" rel="noreferrer">我的薪资</a></li>
                    </ul>
                </Col>
                <Col md={8} xl={6}>
                    <h3>科技专业知识</h3>
                    <ul>
                        <li><a href="https://www.hays-china.cn/web/hays-cn-technology/cloud-computing-jobs" target="_blank" rel="noreferrer">云计算</a></li>
                        <li><a href="https://www.hays-china.cn/web/hays-cn-technology/cyber-security-jobs" target="_blank" rel="noreferrer">网络安全</a></li>
                        <li><a href="https://www.hays-china.cn/web/hays-cn-technology/data-analytics-jobs" target="_blank" rel="noreferrer">数据分析与建模</a></li>
                        <li><a href="https://www.hays-china.cn/web/hays-cn-technology/it-infrastructure-jobs" target="_blank" rel="noreferrer">基础设施</a></li>
                        <li><a href="https://www.hays-china.cn/web/hays-cn-technology/it-projects-change-management-jobs" target="_blank" rel="noreferrer">项目&变革管理</a></li>
                        <li><a href="https://www.hays-china.cn/web/hays-cn-technology/software-development-jobs" target="_blank" rel="noreferrer">软件开发</a></li>
                        <li><a href="https://www.hays-china.cn/web/hays-cn-technology/it-technology-jobs" target="_blank" rel="noreferrer">科技</a></li>
                    </ul>
                </Col>
            </Row>
        </div>
    )
}

export default JobseekerCn;