import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";
import "./AnalyticsLeft.scss";
import eeLeft2 from "./assets/ee/ee-left-2.png";
import AnimatedChart from "./Chart/AnimatedChart";

import erLeft2 from "./assets/er/er-left-2-1.png";
import erLeft3 from "./assets/er/er-left-3.png";
import erLeft4 from "./assets/er/er-left-4.png";
import eeLeft4 from "./assets/ee/ee-left-4.png";
import erLeft5 from "./assets/er/er-left-5.png";
import eeLeft5 from "./assets/ee/ee-left-5-2.png";
import {Context} from "../../app/store";
import AnimatedChartMy from "./Chart/AnimatedChartMy";
import AnimatedChartJp from "./Chart/AnimatedChartJp";
import AnimatedChartHk from "./Chart/AnimatedChartHk";
import AnimatedChartCn from "./Chart/AnimatedChartCn";
import {Image} from "antd";
import ImgPlaceholder from "../Common/Image/ImgPlaceholder";

function AnalyticsLeft({ order, type }) {
    const { t } = useTranslation();
    const [globalState] = useContext(Context);

    return (
        <>
            {order === 1 &&
                <div className={`analytics-left__content ${type === 'employer' ? 'er-1' : 'ee-1'}`}>
                    {type === 'employer' &&
                        <>
                            <div className="em-text">
                                {t('question.resume.employer.employees')}
                            </div>
                            {globalState.location === "sg" &&
                                <div className="er-chart">
                                    <AnimatedChart/>
                                    <div className="item hard">
                                        <div className="per">31%</div>
                                        {t('question.resume.employer.hard')}
                                    </div>
                                    <div className="item soft">
                                        <div className="per">50%</div>
                                        {t('question.resume.employer.soft')}
                                    </div>
                                </div>
                            }
                            {globalState.location === "my" &&
                                <div className="er-chart">
                                    <AnimatedChartMy/>
                                    <div className="item hard">
                                        <div className="per">32%</div>
                                        {t('question.resume.employer.hard')}
                                    </div>
                                    <div className="item soft">
                                        <div className="per">55%</div>
                                        {t('question.resume.employer.soft')}
                                    </div>
                                </div>
                            }
                            {globalState.location === "jp" &&
                                <div className="er-chart">
                                    <AnimatedChartJp/>
                                    <div className="item hard">
                                        <div className="per">23%</div>
                                        {t('question.resume.employer.hard')}
                                    </div>
                                    <div className="item soft">
                                        <div className="per">44%</div>
                                        {t('question.resume.employer.soft')}
                                    </div>
                                    <div className="item others">
                                        <div className="per">33%</div>
                                        {t('question.resume.employer.others')}
                                    </div>
                                </div>
                            }
                            {globalState.location === "hk" &&
                                <div className="er-chart">
                                    <AnimatedChartHk/>
                                    <div className="item hard">
                                        <div className="per">29%</div>
                                        {t('question.resume.employer.hard')}
                                    </div>
                                    <div className="item soft">
                                        <div className="per">46%</div>
                                        {t('question.resume.employer.soft')}
                                    </div>
                                </div>
                            }
                            {globalState.location === "cn" &&
                                <div className="er-chart">
                                    <AnimatedChartCn/>
                                    <div className="item hard">
                                        <div className="per">38%</div>
                                        {t('question.resume.employer.hard')}
                                    </div>
                                    <div className="item soft">
                                        <div className="per">65%</div>
                                        {t('question.resume.employer.soft')}
                                    </div>
                                </div>
                            }
                        </>
                    }
                    {type !== 'employer' &&
                        <>
                            <div className="em-text">
                                {t('question.resume.employee.top')}
                            </div>
                            <div className="item com">
                                <div className="per">
                                    {globalState.location === "sg" && "18%" }
                                    {globalState.location === "my" && "21%" }
                                    {globalState.location === "hk" && "22%" }
                                    {globalState.location === "cn" && "21%" }
                                    {globalState.location === "jp" && "23%" }
                                </div>
                                {t('question.resume.employee.communication')}
                            </div>
                            <div className="item problem">
                                <div className="per">
                                    {globalState.location === "sg" && "19%" }
                                    {globalState.location === "my" && "19%" }
                                    {globalState.location === "hk" && "22%" }
                                    {globalState.location === "cn" && "25%" }
                                    {globalState.location === "jp" && "18%" }
                                </div>
                                {t('question.resume.employee.problem')}
                            </div>
                            <div className="item teamwork">
                                <div className="per">
                                    {globalState.location === "sg" && "17%" }
                                    {globalState.location === "my" && "16%" }
                                    {globalState.location === "hk" && "17%" }
                                    {globalState.location === "cn" && "15%" }
                                    {globalState.location === "jp" && "13%" }
                                </div>
                                {t('question.resume.employee.teamwork')}
                            </div>
                        </>
                    }
                </div>
            }
            {order === 2 &&
                <div className={`analytics-left__content ${type === 'employer' ? 'er-2' : 'ee-2'}`}>
                    {type !== 'employer' &&
                        <>
                            <Image src={eeLeft2} width={242} alt="" fallback={ImgPlaceholder} preview={false} />
                            <div className="em-text">
                                <div className="per">54%</div>
                                {t('question.workplace.employee.said')}
                            </div>
                        </>
                    }
                    {type === 'employer' &&
                        <>
                            <div className="em-text">
                                {t('question.workplace.employer.when')}
                                <div className="per">65%</div>
                                {t('question.workplace.employer.wants')}
                            </div>
                            <Image src={erLeft2} width={219} alt="" fallback={ImgPlaceholder} preview={false} />
                        </>
                    }
                </div>
            }
            {order === 3 &&
                <div className={`analytics-left__content ${type === 'employer' ? 'er-3' : 'ee-3'}`}>
                    {type !== 'employer' &&
                        <>
                            <div className="pie-ee-3 animate no-round">
                                <div className="em-text">
                                    <div className="per">87%</div>
                                    {t('question.salary.employee.of')}
                                </div>
                            </div>
                        </>
                    }
                    {type === 'employer' &&
                        <>
                            <div className="em-text">
                                <div className="per">54%</div>
                                {t('question.salary.employer.are')}
                            </div>
                            <Image src={erLeft3} width={108} alt="" fallback={ImgPlaceholder} preview={false} />
                        </>
                    }
                </div>
            }
            {order === 4 &&
                <div className={`analytics-left__content ${type === 'employer' ? 'er-4' : 'ee-4'}`}>
                    {type === 'employer' &&
                        <>
                            <Image src={erLeft4} width={103} alt="" fallback={ImgPlaceholder} preview={false} />
                            <div className="em-text">
                                <div className="per">90%</div>
                                {t('question.culture.employer.feels')}
                            </div>
                        </>
                    }
                    {type !== 'employer' &&
                        <>
                            <div className="em-text">
                                <div className="per">49%</div>
                                {t('question.culture.employee.recognise')}
                            </div>
                            <Image src={eeLeft4} width={258} alt="" fallback={ImgPlaceholder} preview={false} />
                        </>
                    }
                </div>
            }
            {order === 5 &&
                <div className={`analytics-left__content ${type === 'employer' ? 'er-5' : 'ee-5'}`}>
                    {type === 'employer' &&
                        <>
                            <Image src={erLeft5} width={220} alt="" fallback={ImgPlaceholder} preview={false} />
                            <div className="em-text">
                                <div className="per">70%</div>
                                {t('question.tech.employer.wants')}
                            </div>
                        </>
                    }
                    {type !== 'employer' &&
                        <>
                            <div className="em-text">
                                <div className="per">
                                    {globalState.location === "sg" && "36%" }
                                    {globalState.location === "my" && "40%" }
                                    {globalState.location === "hk" && "56%" }
                                    {globalState.location === "cn" && "56%" }
                                    {globalState.location === "jp" && "44%" }
                                </div>
                                {t('question.tech.employee.had')}
                            </div>
                            <Image src={eeLeft5} width={208} alt="" fallback={ImgPlaceholder} preview={false} />
                        </>
                    }
                </div>
            }
        </>
    )
}

export default AnalyticsLeft;