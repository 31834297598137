import './App.css';
import Store from "./app/store";
import AppRouter from "./AppRouter";
import './i18n';

function App() {
  return (
      <div className="App">
        <Store>
          <AppRouter />
        </Store>
      </div>
  );
}

export default App;
