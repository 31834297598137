import React from 'react';
import iconResume from "./assets/icons/icon-question-resume.png";
import iconWorkplace from "./assets/icons/icon-question-workplace.png";
import iconSalary from "./assets/icons/icon-salary.png";
import iconApple from "./assets/icons/icon-apple.png";
import iconTech from "./assets/icons/icon-question-tech.png";
import {Image} from "antd";
import ImgPlaceholder from "../Common/Image/ImgPlaceholder";

function IconQuestion({ order }) {
    return (
        <>
            {order === 1 &&
                <Image src={iconResume} rootClassName="question-icon" width={88} height={72} alt="" fallback={ImgPlaceholder} preview={false} />
            }
            {order === 2 &&
                <Image src={iconWorkplace} rootClassName="question-icon" width={99} height={63} alt="" fallback={ImgPlaceholder} preview={false} />
            }
            {order === 3 &&
                <Image src={iconSalary} rootClassName="question-icon" width={57} height={70} alt="" fallback={ImgPlaceholder} preview={false} />
            }
            {order === 4 &&
                <Image src={iconApple} rootClassName="question-icon" width={59} height={72} alt="" fallback={ImgPlaceholder} preview={false} />
            }
            {order === 5 &&
                <Image src={iconTech} rootClassName="question-icon" width={52} height={63} alt="" fallback={ImgPlaceholder} preview={false} />
            }
        </>
    )
}

export default IconQuestion;
