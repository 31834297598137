import React from 'react';
import {Col, Row} from "antd";

function ExpertsHk() {
    return (
        <>
            <h2>Experts in Technology</h2>
            <Row>
                <Col xs={12} lg={6}>
                    <h4>Popular Links</h4>
                    <ul>
                        <li><a href="https://m.hays.com.hk/it/search" target="_blank" rel="noreferrer">Search jobs</a></li>
                        <li><a href="https://m.hays.com.hk/it/speculativeCV/" target="_blank" rel="noreferrer">Submit CV</a></li>
                        <li><a href="https://www.hays.com.hk/it/it-salary-check-hongkongsar" target="_blank" rel="noreferrer">Check my salary</a></li>
                        <li><a href="https://www.hays.com.hk/it/register-a-vacancy" target="_blank" rel="noreferrer">Register job</a></li>
                        <li><a href="https://www.hays.com.hk/it/technology-salary-guide" target="_blank" rel="noreferrer">Technology salary guide</a></li>
                        <li><a href="https://m.hays.com.hk/it/all-offices" target="_blank" rel="noreferrer">Contact us</a></li>
                    </ul>
                </Col>
                <Col xs={12} lg={6}>
                    <h4>Explore Hays Hong Kong SAR</h4>
                    <ul>
                        <li><a href="https://www.hays.com.hk/" target="_blank" rel="noreferrer">hays.com.hk</a></li>
                        <li><a href="https://www.hays.com.hk/career-advice" target="_blank" rel="noreferrer">Career advice</a></li>
                        <li><a href="https://m.hays.com.hk/search" target="_blank" rel="noreferrer">Search all jobs</a></li>
                        <li><a href="https://www.hays.com.hk/news" target="_blank" rel="noreferrer">Press & media</a></li>
                    </ul>
                </Col>
                <Col xs={12} lg={6}>
                    <h4>Information</h4>
                    <ul>
                        <li><a href="https://www.hays.com.hk/it/offices" target="_blank" rel="noreferrer">Office contact info</a></li>
                        <li><a href="https://www.hays.com.hk/accessibility-guidelines" target="_blank" rel="noreferrer">Accessibility</a></li>
                        <li><a href="https://www.hays.com.hk/it/sitemap" target="_blank" rel="noreferrer">Sitemap</a></li>
                        <li><a href="https://www.hays.com.hk/it/about-us" target="_blank" rel="noreferrer">About us</a></li>
                        <li><a href="https://www.hays.com.hk/scam-alert" target="_blank" rel="noreferrer">Scam alert</a></li>
                        <li><a href="https://www.hays.com.hk/site-help" target="_blank" rel="noreferrer">Help</a></li>
                    </ul>
                </Col>
                <Col xs={12} lg={6}>
                    <h4>Policies</h4>
                    <ul>
                        <li><a href="https://www.hays.com.hk/terms-conditions" target="_blank" rel="noreferrer">Terms & Conditions</a></li>
                        <li><a href="https://www.hays.com.hk/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a></li>
                        <li><a href="https://www.hays.com.hk/disclaimer" target="_blank" rel="noreferrer">Disclaimer</a></li>
                    </ul>
                </Col>
            </Row>
        </>
    )
}

export default ExpertsHk;