import React from 'react';
import {Col, Row} from "antd";

function ExpertsSg() {
    return (
        <>
            <h2>Experts in Technology</h2>
            <Row>
                <Col xs={12} lg={6}>
                    <h4>Popular Links</h4>
                    <ul>
                        <li><a href="https://m.hays.com.sg/it/search" target="_blank" rel="noreferrer">Search jobs</a></li>
                        <li><a href="https://m.hays.com.sg/it/speculativeCV" target="_blank" rel="noreferrer">Submit CV</a></li>
                        <li><a href="https://www.hays.com.sg/it/it-salary-check-singapore" target="_blank" rel="noreferrer">Check my salary</a></li>
                        <li><a href="https://www.hays.com.sg/it/register-a-vacancy" target="_blank" rel="noreferrer">Register job</a></li>
                        <li><a href="https://www.hays.com.sg/salary-guide" target="_blank" rel="noreferrer">Salary Guide</a></li>
                        <li><a href="https://m.hays.com.sg/it/all-offices" target="_blank" rel="noreferrer">Contact us</a></li>
                        <li><a href="https://hays.ramcoes.com/rvw/hub/index.html" target="_blank" rel="noreferrer">Timesheets</a></li>
                    </ul>
                </Col>
                <Col xs={12} lg={6}>
                    <h4>Explore Hays Singapore</h4>
                    <ul>
                        <li><a href="https://www.hays.com.sg/" target="_blank" rel="noreferrer">hays.com.sg</a></li>
                        <li><a href="https://www.hays.com.sg/advice-services" target="_blank" rel="noreferrer">Career advice</a></li>
                        <li><a href="https://m.hays.com.sg/search" target="_blank" rel="noreferrer">Search all jobs</a></li>
                        <li><a href="https://www.hays.com.sg/media-centre" target="_blank" rel="noreferrer">Press & media</a></li>
                    </ul>
                </Col>
                <Col xs={12} lg={6}>
                    <h4>Information</h4>
                    <ul>
                        <li><a href="https://www.hays.com.sg/it/offices" target="_blank" rel="noreferrer">Offices</a></li>
                        <li><a href="https://www.hays.com.sg/accessibility-guidelines" target="_blank" rel="noreferrer">Accessibility</a></li>
                        <li><a href="https://www.hays.com.sg/it/sitemap" target="_blank" rel="noreferrer">Sitemap</a></li>
                        <li><a href="https://www.hays.com.sg/it/about-us" target="_blank" rel="noreferrer">About us</a></li>
                        <li><a href="https://www.hays.com.sg/scam-alert" target="_blank" rel="noreferrer">Scam alert</a></li>
                        <li><a href="https://www.hays.com.sg/site-help" target="_blank" rel="noreferrer">Help</a></li>
                    </ul>
                </Col>
                <Col xs={12} lg={6}>
                    <h4>Policies</h4>
                    <ul>
                        <li><a href="https://www.hays.com.sg/terms-and-conditions" target="_blank" rel="noreferrer">Terms & Conditions</a></li>
                        <li><a href="https://www.hays.com.sg/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a></li>
                        <li><a href="https://www.hays.com.sg/disclaimer" target="_blank" rel="noreferrer">Disclaimer</a></li>
                    </ul>
                </Col>
            </Row>
        </>
    )
}

export default ExpertsSg;