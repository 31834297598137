/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */

import React, {useContext, useState} from 'react';
import "./HeaderMobile.scss";
import {Header} from "antd/es/layout/layout";
import {Context} from "../../../app/store";
import logo from "./assets/layout_set_logo.jpg"
import burger from "./assets/burguer.svg"
import {Drawer, Row} from "antd";
import {useTranslation} from "react-i18next";
import NavSgMobile from "../Header/nav/NavSgMobile";
import NavCnMobile from "../Header/nav/NavCnMobile";
import NavMyMobile from "../Header/nav/NavMyMobile";
import NavHkMobile from "../Header/nav/NavHkMobile";
import NavJpMobile from "../Header/nav/NavJpMobile";

function HaysHeaderMobile() {
    const {t} = useTranslation();
    const [globalState] = useContext(Context);
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    }

    const onClose = () => {
        setMenuOpen(false);
    }

    return (
        <React.Fragment>

            <Drawer
                placement="right"
                onClose={()=>onClose()}
                visible={menuOpen}
                width={window.innerWidth > 900 ? 800 : window.innerWidth - 75}
                header={null}
                // closable={false}
                className={"drawer-mobile"}
            >
                <nav id="nav-main-mobile">
                    {globalState.location === "sg" && <NavSgMobile />}
                    {globalState.location === "cn" && <NavCnMobile />}
                    {globalState.location === "my" && <NavMyMobile />}
                    {globalState.location === "hk" && <NavHkMobile />}
                    {globalState.location === "jp" && <NavJpMobile />}
                </nav>
            </Drawer>

            <Header className={"hays-header-mobile"}>
                <div className="header-main-wrapper">
                    <Row className="row" id="header-main" justify={"space-between"}>
                        <div className="logo-wrapper">
                            {globalState.location === "sg" &&
                                <a className="logo custom-logo" href="https://www.hays.com.sg/it/" target="_blank" rel="noreferrer">
                                    <img alt="Hays" src={logo}/>
                                </a>
                            }
                            {globalState.location === "my" &&
                                <a className="logo custom-logo" href="https://www.hays.com.my/it/" target="_blank" rel="noreferrer">
                                    <img alt="Hays" src={logo}/>
                                </a>
                            }
                            {globalState.location === "hk" &&
                                <a className="logo custom-logo" href="https://www.hays.com.hk/it" target="_blank" rel="noreferrer">
                                    <img alt="Hays" src={logo}/>
                                </a>
                            }
                            {globalState.location === "cn" &&
                                <a className="logo custom-logo" href="https://www.hays-china.cn/it" target="_blank" rel="noreferrer">
                                    <img alt="Hays" src={logo}/>
                                </a>
                            }
                            {globalState.location === "jp" &&
                                <a className="logo custom-logo" href="https://www.hays.co.jp/it/" target="_blank" rel="noreferrer">
                                    <img alt="Hays" src={logo}/>
                                </a>
                            }
                        </div>
                        <button className={"hamburguer"} onClick={() => toggleMenu()}>
                            <img src={burger} alt="Mobile menu"/>
                        </button>
                    </Row>
                </div>

                <div className="separator" />

                <div className="main-title">
                    <h1>{t('title')}</h1>
                    <div className="green-separator" />
                </div>
            </Header>
        </React.Fragment>
    );
}

export default HaysHeaderMobile;