import React from 'react';
import imgResume from "./assets/resume/img-question-resume.jpg";
import imgWorkplace from "./assets/img-question-workplace.jpg";
import imgSalary from "./assets/img-question-salary.jpg";
import imgCulture from "./assets/img-question-culture.jpg";
import imgTech from "./assets/img-question-tech.jpg";

import imgResumeMobile from "./assets/resume/img-question-resume-mobile.jpg";
import {Image} from "antd";
import ImgPlaceholder from "../Common/Image/ImgPlaceholder";

function ImgQuestion({ order }) {
    return (
        <>
            {order === 1 &&
                <React.Fragment>
                    <Image src={imgResume} alt="" rootClassName="desktop" fallback={ImgPlaceholder} preview={false} />
                    <Image src={imgResumeMobile} alt="" rootClassName="mobile" fallback={ImgPlaceholder} preview={false} />
                </React.Fragment>
            }
            {order === 2 &&
                <Image src={imgWorkplace} alt="" fallback={ImgPlaceholder} preview={false} />
            }
            {order === 3 &&
                <Image src={imgSalary} alt="" fallback={ImgPlaceholder} preview={false} />
            }
            {order === 4 &&
                <Image src={imgCulture} alt="" fallback={ImgPlaceholder} preview={false} />
            }
            {order === 5 &&
                <Image src={imgTech} alt="" fallback={ImgPlaceholder} preview={false} />
            }
        </>
    )
}

export default ImgQuestion;
