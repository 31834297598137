import React from 'react';
import "./AnimatedChart.scss";
import "./AnimatedChartMy.scss";
function AnimatedChartMy() {
    return (
        <>
            <div className="pie pie-bottom-my"></div>
            <div className="pie pie-left-my"></div>
            <div className="pie pie-right-my"></div>
        </>
    );
}
export default AnimatedChartMy;