import React from 'react';
import {Dropdown} from "antd";
import JobseekerMy from "../links/JobseekerMy";
import EmployerMy from "../links/EmployerMy";

function NavMy() {
    return (
        <ul>
            <li className="first">
                <a href="https://www.hays.com.my/it/about-us" target="_blank" rel="noreferrer">About Us</a>
            </li>
            <li className="first">
                <Dropdown overlay={<JobseekerMy/>} overlayClassName="megaMenuOverlay" trigger={['click']} getPopupContainer={() => document.getElementById('nav-main')}>
                    <button type="link" onClick={(e) => e.preventDefault()}>
                        Jobseeker <span className="arrow-down-icon"></span>
                    </button>
                </Dropdown>
            </li>
            <li className="first">
                <Dropdown overlay={<EmployerMy/>} overlayClassName="megaMenuOverlay" trigger={['click']} getPopupContainer={() => document.getElementById('nav-main')}>
                    <button type="link" onClick={(e) => e.preventDefault()}>
                        Employer <span className="arrow-down-icon"></span>
                    </button>
                </Dropdown>
            </li>
            <li className="first">
                <a href="https://www.hays.com.my/join-hays" target="_blank" rel="noreferrer">Join Hays</a>
            </li>
            <li className="first">
                <a href="https://m.hays.com.my/it/all-offices" target="_blank" rel="noreferrer">Get in Touch</a>
            </li>
            <li className="first">
                <a href="https://www.haysplc.com/investors" target="_blank" rel="noreferrer">Investor</a>
            </li>
        </ul>
    )
}

export default NavMy;