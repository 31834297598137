import React from 'react';
import {Col, Row} from "antd";

function ExpertsMy() {
    return (
        <>
            <h2>Experts in Technology</h2>
            <Row>
                <Col xs={12} lg={6}>
                    <h4>Popular Links</h4>
                    <ul>
                        <li><a href="https://m.hays.com.my/it/search/" target="_blank" rel="noreferrer">Search jobs</a></li>
                        <li><a href="https://m.hays.com.my/it/speculativeCV/" target="_blank" rel="noreferrer">Submit CV</a></li>
                        <li><a href="https://www.hays.com.my/it/it-salary-check-malaysia" target="_blank" rel="noreferrer">Check my salary</a></li>
                        <li><a href="https://www.hays.com.my/it/register-a-vacancy" target="_blank" rel="noreferrer">Register job</a></li>
                        <li><a href="https://www.hays.com.my/salary-guide" target="_blank" rel="noreferrer">Salary Guide</a></li>
                        <li><a href="https://m.hays.com.my/it/all-offices" target="_blank" rel="noreferrer">Contact us</a></li>
                    </ul>
                </Col>
                <Col xs={12} lg={6}>
                    <h4>Explore Hays Malaysia</h4>
                    <ul>
                        <li><a href="https://www.hays.com.my/" target="_blank" rel="noreferrer">hays.com.my</a></li>
                        <li><a href="https://www.hays.com.my/advice-services" target="_blank" rel="noreferrer">Career advice</a></li>
                        <li><a href="https://m.hays.com.my/search" target="_blank" rel="noreferrer">Search all jobs</a></li>
                        <li><a href="https://www.hays.com.my/media-centre" target="_blank" rel="noreferrer">Press & media</a></li>
                    </ul>
                </Col>
                <Col xs={12} lg={6}>
                    <h4>Information</h4>
                    <ul>
                        <li><a href="https://www.hays.com.my/it/offices" target="_blank" rel="noreferrer">Offices</a></li>
                        <li><a href="https://www.hays.com.my/general-content/hays_178709" target="_blank" rel="noreferrer">Accessibility</a></li>
                        <li><a href="https://www.hays.com.my/it/sitemap" target="_blank" rel="noreferrer">Sitemap</a></li>
                        <li><a href="https://www.hays.com.my/it/about-us/" target="_blank" rel="noreferrer">About us</a></li>
                        <li><a href="https://www.hays.com.my/scam-alert" target="_blank" rel="noreferrer">Scam alert</a></li>
                        <li><a href="https://www.hays.com.my/general-content/hays_375027" target="_blank" rel="noreferrer">Help</a></li>
                    </ul>
                </Col>
                <Col xs={12} lg={6}>
                    <h4>Policies</h4>
                    <ul>
                        <li><a href="https://www.hays.com.my/general-content/hays_178666" target="_blank" rel="noreferrer">Terms & Conditions</a></li>
                        <li><a href="https://www.hays.com.my/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a></li>
                        <li><a href="https://www.hays.com.my/disclaimer" target="_blank" rel="noreferrer">Disclaimer</a></li>
                    </ul>
                </Col>
            </Row>
        </>
    )
}

export default ExpertsMy;