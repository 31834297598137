import React, {useContext, useEffect, useState} from 'react';
import {Context} from "../../app/store";
import "./PageQuestion.scss";
import {Button, Col, message, Row} from "antd";
import {GOTO_PAGE, NEXT_QUESTION, PAGE_END} from "../../app/types";
import axios from "axios";
import {API_ENTRY} from "../../constants";
import {useTranslation} from "react-i18next";
import iconHeart from "./assets/icons/icon-heart.png";
import iconClose from "./assets/icons/icon-close.png";
import {CaretLeftOutlined, CaretRightOutlined, CaretUpOutlined, HeartFilled} from "@ant-design/icons";
import ImgQuestion from "./ImgQuestion";
import IconQuestion from "./IconQuestion";
import MatchDesc from "./MatchDesc";
import AnalyticsRight from "./AnalyticsRight";
import AnalyticsLeft from "./AnalyticsLeft";
import i18next from "i18next";

function PageQuestion() {
    const { t } = useTranslation();
        const [globalState, dispatch] = useContext(Context);
    const [showGraph, setShowGraph] = useState(false);
    const [percentage, setPercentage] = useState(0);

    const initialState = {
        error: null,
        loading: false,
        countYes: 0,
        countNo: 0
    };
    const [state, setState] = useState(initialState);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [state.countYes, state.countNo, globalState.question])


    const onFinish = (value) => {
        // console.log(value);
        if(state.loading){
            return false;
        }
        setState({
            ...state,
            loading: true,
        });

        let payload = {
            "sessionId": globalState.sessionId,
            "question": globalState.questions[globalState.question].id,
            "answer": value,
            "role" : globalState.type
        };

        axios.post(API_ENTRY, payload)
            .then(response => {
                let result = response.data.payload;
                
                if(!result || result.length === 0){
                    setPercentage(null);
                }else if(result.length === 1){
                    let v = result.find(x=> x.answer === value)
                    if(v){
                        setPercentage(v.percent);
                    }else{
                        setPercentage(100 - result[0].percent);
                    }
                }else if(result.length === 2){ // We look for the one
                    let v = result.find(x=> x.answer === value)
                    setPercentage(v.percent);
                }

                let plusNo = 0;
                let plusYes = 0;
                if(value){ plusYes = 1; } else { plusNo = 1; }
                setState({
                    ...state,
                    error: null,
                    loading: false,
                    countNo: state.countNo + plusNo,
                    countYes: state.countYes + plusYes,
                });
                setShowGraph(true);
            })
            .catch(error => {
                let msg = error?.response?.data?.statusCode?.msg
                setState({
                    ...state,
                    error: msg ?? null,
                    loading: false,
                })
            })
    }
    const goUp = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
    const goBack = () => {
       setShowGraph(false);
    }
    const goToNextQuestion = () => {
        setShowGraph(false);

        if ((globalState.question + 1) < globalState.questions.length) {
            // console.table(state);
            dispatch({
                type: NEXT_QUESTION,
            });
        } else {
            dispatch({
                type: GOTO_PAGE,
                payload: {
                    page: PAGE_END,
                    progress: 7,
                    answertype: state.countYes > state.countNo ? "yes" : "no"
                },
            });
        }
    }

    return (
        <div className={'page-question-wrapper'}>
            <div className={`page-question q_${globalState.question}`}>
                {!showGraph &&
                    <Row className={"question-inner"} justify="center">
                        <Col className="question-img-left" lg={8}>
                            <ImgQuestion order={globalState.questions[globalState.question].order} />
                        </Col>
                        <Col lg={16}>
                            <div className="question-wrap">
                                <div className="question-wrap-text question-text-top">
                                    {globalState.type === "Employer" &&
                                        globalState.questions[globalState.question].employer
                                    }
                                    {globalState.type === "Employee" &&
                                        globalState.questions[globalState.question].employee
                                    }
                                    <IconQuestion order={globalState.questions[globalState.question].order} />
                                </div>
                                <div className="form-container">
                                    {state.error &&
                                        // <Alert message={state.error} type="error" showIcon/>
                                        message.error(state.error, 7)
                                    }
                                    <div className="yes-no">
                                        <Button id={"no-btn-" + globalState.question + "-" + globalState.location + "-" + globalState.type}
                                                onClick={() => onFinish(false)}
                                                className="no-btn">
                                            <img src={iconClose} width="43" height="43" alt=""/>
                                        </Button>
                                        <div className="yes-no-text">
                                            <span>{t('question.yes')}</span>
                                            <span>{t('question.or')}</span>
                                            <span>{t('question.no')}</span>
                                        </div>
                                        <Button id={"yes-btn-" + globalState.question + "-" + globalState.location + "-" + globalState.type}
                                            onClick={() => onFinish(true)} className="yes-btn">
                                            <img src={iconHeart} width="46" height="41" alt=""/>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                }

                {showGraph &&
                    <>
                        <Row className={"question-inner"} justify="center">
                            <Col className="question-wrap-text question-bg-left" md={24} lg={8}>
                                {globalState.type === "Employer" &&
                                    globalState.questions[globalState.question].employer
                                }
                                {globalState.type === "Employee" &&
                                    globalState.questions[globalState.question].employee
                                }
                                <IconQuestion order={globalState.questions[globalState.question].order} />
                            </Col>
                            <Col md={24} lg={16}>
                                <div className={`graph-container q_${globalState.question}`}>
                                    <div className={`percentage-match ${globalState.type.toLowerCase()}`}>
                                        <div className="heart-icon"><HeartFilled /></div>
                                        <MatchDesc order={globalState.questions[globalState.question].order} type={globalState.type.toLowerCase()} percentage={percentage?.toFixed(0)} />
                                        <Button id={"next-btn-" + globalState.question + "-" + globalState.location + "-" + globalState.type}
                                            type="primary" onClick={() => goToNextQuestion()} className="btn next-question">{t('question.next')} <CaretRightOutlined className={"caret-right"} /></Button>
                                    </div>
                                    <div className="analytics-height">
                                        <div className="analytics-left">
                                            <AnalyticsLeft order={globalState.questions[globalState.question].order} type={globalState.type.toLowerCase()} />
                                        </div>
                                        <div className="analytics-right">
                                            <AnalyticsRight order={globalState.questions[globalState.question].order} type={globalState.type.toLowerCase()} />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <p style={{textAlign: "left"}}>{t('question.sources')}</p>
                        </Row>
                        <Row justify="space-between">
                            <Button id={"back-btn-" + globalState.question + "-" + globalState.location + "-" + globalState.type}
                                type="primary" onClick={() => goBack()} className={`btn go-back go-back-${i18next.language}`}><CaretLeftOutlined/> {t('question.goback')}</Button>
                            <Button id={"go-top-btn-" + globalState.question + "-" + globalState.location + "-" + globalState.type}
                                type="primary" onClick={() => goUp()} className={`btn go-up go-up-${i18next.language} mobile`}>{t('question.goup')}<CaretUpOutlined/></Button>
                        </Row>
                    </>
                }
            </div>
        </div>
    )
}

export default PageQuestion;
