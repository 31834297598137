import React, {useContext} from 'react';
import "./Footer.scss";
import {Footer} from "antd/es/layout/layout";
import {Context} from "../../../app/store";
import ExpertsSg from "./ExpertsSg";
import ExpertsCn from "./ExpertsCn";
import ExpertsMy from "./ExpertsMy";
import ExpertsHk from "./ExpertsHk";
import ExpertsJp from "./ExpertsJp";

function HaysFooter() {
    const [globalState] = useContext(Context);

    return (
        <Footer className={"hays-footer"}>
            <div className="footer-top">
                {globalState.location === "sg" &&
                    <ExpertsSg/>
                }
                {globalState.location === "cn" &&
                    <ExpertsCn/>
                }
                {globalState.location === "my" &&
                    <ExpertsMy/>
                }
                {globalState.location === "hk" &&
                    <ExpertsHk/>
                }
                {globalState.location === "jp" &&
                    <ExpertsJp/>
                }
            </div>
            <div className="hays-footer-content">
                {globalState.location === "sg" &&
                    <div className={"first"}>Hays Specialist Recruitment PTE Limited (EA Licence No. 07C3924)</div>
                }
                {globalState.location === "my" &&
                    <div className={"first"}>
                        Agensi Pekerjaan Hays (Malaysia) Sdn Bhd (Company Registration No: 955738-T)<br/>
                        EA License Number: JTKSM 229C (KLCC), JTKSM 229C-1C (SUNWAY)
                    </div>
                }
                {globalState.location === "hk" &&
                    <div className={"first"}>Hays Hong Kong Limited (EA Licence No. 68028)</div>
                }
                <p>
                    {globalState.location === "sg" &&
                        <a href="https://www.hays.com.sg/hays-copyright-notice" target={"_blank"} rel="noreferrer"
                           className="copyright">
                            © Copyright Hays plc 2022. The HAYS word, the H devices, HAYS WORKING FOR YOUR TOMORROW and
                            Powering the world of work and associated logos and artwork are trademarks of Hays plc.
                            The H devices are original designs protected by registration in many countries. All rights
                            are reserved.
                        </a>
                    }
                    {globalState.location === "my" &&
                        <a href="https://www.hays.com.my/hays-copyright-notice" target={"_blank"} rel="noreferrer"
                           className="copyright">
                            © Copyright Hays plc 2022. The HAYS word, the H devices, HAYS WORKING FOR YOUR TOMORROW and
                            Powering the world of work and associated logos and artwork are trademarks of Hays plc.
                            The H devices are original designs protected by registration in many countries. All rights
                            are reserved.
                        </a>
                    }
                    {globalState.location === "hk" &&
                        <a href="https://www.hays.com.au/copyright-notice" target={"_blank"} rel="noreferrer"
                           className="copyright">
                            © Copyright Hays plc 2022. The HAYS word, the H devices, HAYS WORKING FOR YOUR TOMORROW and
                            Powering the world of work and associated logos and artwork are trademarks of Hays plc.
                            The H devices are original designs protected by registration in many countries. All rights
                            are reserved.
                        </a>
                    }
                    {globalState.location === "cn" &&
                        <>
                            {window.location.hostname.endsWith("mediatropy.cn") &&
                                <a href="https://beian.miit.gov.cn" rel="noreferrer" target="_blank">沪ICP备19020378号-1</a>
                            }
                            {!window.location.hostname.endsWith("mediatropy.cn") &&
                                <a href="https://beian.miit.gov.cn" rel="noreferrer" target="_blank">沪ICP备2021002684号-1</a>
                            }

                        <br/>
                        <a href="https://www.hays-china.cn/copyright" target={"_blank"} rel="noreferrer"
                           className="copyright">
                            © Copyright Hays plc 2022。“HAYS”、“H”图案、“HAYS WORKING FOR YOUR TOMORROW”和“Powering the
                            world of work”及相关标志和图片均为Hays plc持有商标。“H”图案是在多个国家注册且受到保护的原创设计。
                            Hays plc保留所有权利。
                        </a>

                        </>
                    }
                    {globalState.location === "jp" &&
                        <a href="https://www.hays.co.jp/copyright" target={"_blank"} rel="noreferrer"
                           className="copyright">
                            © Copyright Hays plc 2022. HAYSの文字、Hの意匠、HAYS WORKING FOR YOUR TOMORROW、Powering the
                            World of Work、および関連するロゴ、アートワークは、HAYSの商標です。Hの意匠は、多くの国々で登録されているオリジナルデザインであり、すべての著作権は弊社に帰属します。
                        </a>
                    }
                </p>
            </div>
        </Footer>
    );
}

export default HaysFooter;