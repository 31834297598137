import React from 'react';
import {Col, Row} from "antd";

function EmployerJp() {
    return (
        <div className="megaMenu">
            <Row gutter={30}>
                <Col md={6} xl={5}>
                    <h3>サービス一覧</h3>
                    <ul>
                        <li><a href="https://www.hays.co.jp/it/register-a-vacancy" target="_blank" rel="noreferrer">求人を登録する</a></li>
                        <li><a href="https://www8.hays.co.jp/HaysOnlineJapan/Login.aspx" target="_blank" rel="noreferrer">タイムシート</a></li>
                        <li><a href="https://www.hays.co.jp/salary-guide" target="_blank" rel="noreferrer">ヘイズアジア給与ガイド</a></li>
                    </ul>
                </Col>
                <Col md={8} xl={6}>
                    <h3>専門分野</h3>
                    <ul>
                        <li><a href="https://www.hays.co.jp/it/cloud-computing-recruitment" target="_blank" rel="noreferrer">クラウドコンピューティング</a></li>
                        <li><a href="https://www.hays.co.jp/it/cyber-security-recruitment" target="_blank" rel="noreferrer">サイバーセキュリティ</a></li>
                        <li><a href="https://www.hays.co.jp/it/data-analytics-recruitment" target="_blank" rel="noreferrer">データ・アドバンストアナリティクス</a></li>
                        <li><a href="https://www.hays.co.jp/it/it-infrastructure-recruitment" target="_blank" rel="noreferrer">インフラストラクチャー</a></li>
                        <li><a href="https://www.hays.co.jp/it/it-projects-change-management-recruitment" target="_blank" rel="noreferrer">プロジェクトマネージメント・ チェンジマネージメント（PM)</a></li>
                        <li><a href="https://www.hays.co.jp/it/software-development-recruitment" target="_blank" rel="noreferrer">ソフトウェア開発</a></li>
                    </ul>
                </Col>
                <Col md={8} xl={6}>
                    <h3>サービス紹介</h3>
                    <ul>
                        <li><a href="https://www.hays.co.jp/it/it-recruitment" target="_blank" rel="noreferrer">正社員・契約社員求人のご紹介</a></li>
                        <li><a href="https://www.hays.co.jp/it/msp-recruitment" target="_blank" rel="noreferrer">人材管理サービス（MSP）</a></li>
                        <li><a href="https://www.hays.co.jp/it/rpo-recruitment" target="_blank" rel="noreferrer">採用アウトソーシング（RPO）</a></li>
                    </ul>
                </Col>
            </Row>
        </div>
    )
}

export default EmployerJp;