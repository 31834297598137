import React from 'react';
import {Col, Row} from "antd";

function ExpertsJp() {
    return (
        <>
            <h2>IT転職エキスパート ヘイズ</h2>
            <Row>
                <Col xs={12} lg={6}>
                    <h4>リンク集</h4>
                    <ul>
                        <li><a href="https://m.hays.co.jp/it/search" target="_blank" rel="noreferrer">IT求人を探す</a></li>
                        <li><a href="https://m.hays.co.jp/it/speculativeCV" target="_blank" rel="noreferrer">職務経歴書を提出する</a></li>
                        <li><a href="https://www.hays.co.jp/salary-check" target="_blank" rel="noreferrer">年収査定</a></li>
                        <li><a href="https://www.hays.co.jp/it/register-a-vacancy" target="_blank" rel="noreferrer">求人を登録する</a></li>
                        <li><a href="https://www.hays.co.jp/salary-guide" target="_blank" rel="noreferrer">給与ガイド</a></li>
                        <li><a href="https://m.hays.co.jp/it/office-locator" target="_blank" rel="noreferrer">お問い合わせ</a></li>
                        <li><a href="https://www8.hays.co.jp/HaysOnlineJapan/Login.aspx" target="_blank" rel="noreferrer">タイムシート</a></li>
                    </ul>
                </Col>
                <Col xs={12} lg={6}>
                    <h4>ヘイズ・ジャパン</h4>
                    <ul>
                        <li><a href="https://www.hays.co.jp/" target="_blank" rel="noreferrer">ホームページ</a></li>
                        <li><a href="https://www.hays.co.jp/advice" target="_blank" rel="noreferrer">転職アドバイス</a></li>
                        <li><a href="https://m.hays.co.jp/search" target="_blank" rel="noreferrer">すべての求人を探す</a></li>
                        <li><a href="https://www.hays.co.jp/news" target="_blank" rel="noreferrer">ニュース</a></li>
                    </ul>
                </Col>
                <Col xs={12} lg={6}>
                    <h4>関連情報</h4>
                    <ul>
                        <li><a href="https://www.hays.co.jp/it/offices" target="_blank" rel="noreferrer">国内拠点</a></li>
                        <li><a href="https://www.hays.co.jp/general-content/HAYS_373000" target="_blank" rel="noreferrer">アクセシビリティに関する指針</a></li>
                        <li><a href="https://www.hays.co.jp/it/sitemap" target="_blank" rel="noreferrer">サイトマップ</a></li>
                        <li><a href="https://www.hays.co.jp/it/about-us" target="_blank" rel="noreferrer">ヘイズについて</a></li>
                        <li><a href="https://www.hays.co.jp/scam-alert" target="_blank" rel="noreferrer">求職者を狙った詐欺に注意</a></li>
                        <li><a href="https://www.hays.co.jp/general-content/HAYS_373391" target="_blank" rel="noreferrer">ヘルプ</a></li>
                    </ul>
                </Col>
                <Col xs={12} lg={6}>
                    <h4>ポリシー</h4>
                    <ul>
                        <li><a href="https://www.hays.co.jp/termsandconditions" target="_blank" rel="noreferrer">利用規約</a></li>
                        <li><a href="https://www.hays.co.jp/privacypolicy" target="_blank" rel="noreferrer">個人情報保護方針</a></li>
                        <li><a href="https://www.hays.co.jp/disclaimer" target="_blank" rel="noreferrer">免責事項</a></li>
                    </ul>
                </Col>
            </Row>
        </>
    )
}

export default ExpertsJp;