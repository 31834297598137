import React from 'react';
import {Menu} from "antd";

function NavHkMobile() {
    const dropdownJobseeker = [
        {
            key: '1',
            label: (
                <>Jobseeker <span className="arrow-down-icon"></span></>
            ),
            children: [
                {
                    type: 'group',
                    label: 'Your Career',
                    children: [
                        {
                            label: <a href="https://m.hays.com.hk/it/search" target="_blank" rel="noreferrer">Search jobs</a>,
                            key: 'yc1',
                        },
                        {
                            label: (<a href="https://m.hays.com.hk/it/speculativeCV/" target="_blank" rel="noreferrer">Submit my CV</a>),
                            key: 'yc2',
                        },
                        {
                            label: (<a href="https://hays.ramcoes.com/rvw" target="_blank" rel="noreferrer">Timesheets</a>),
                            key: 'yc3',
                        },
                        {
                            label: (<a href="https://www.hays.com.hk/it/it-salary-check-hongkongsar" target="_blank" rel="noreferrer">My salary</a>),
                            key: 'yc4',
                        },
                    ],
                },
                {
                    type: 'group',
                    label: 'Technology Expertise',
                    children: [
                        {
                            label: (<a href="https://www.hays.com.hk/it/cloud-computing-jobs" target="_blank" rel="noreferrer">Cloud Computing</a>),
                            key: 'te1',
                        },
                        {
                            label: (<a href="https://www.hays.com.hk/it/cyber-security-jobs" target="_blank" rel="noreferrer">Cyber Security</a>),
                            key: 'te2',
                        },
                        {
                            label: (<a href="https://www.hays.com.hk/it/data-analytics-jobs" target="_blank" rel="noreferrer">Data & Advanced Analytics</a>),
                            key: 'te3',
                        },
                        {
                            label: (<a href="https://www.hays.com.hk/it/it-infrastructure-jobs" target="_blank" rel="noreferrer">Infrastructure</a>),
                            key: 'te4',
                        },
                        {
                            label: (<a href="https://www.hays.com.hk/it/it-projects-change-management-jobs" target="_blank" rel="noreferrer">Projects & Change Management</a>),
                            key: 'te5',
                        },
                        {
                            label: (<a href="https://www.hays.com.hk/it/software-development-jobs" target="_blank" rel="noreferrer">Software Development</a>),
                            key: 'te6',
                        },
                        {
                            label: (<a href="https://www.hays.com.hk/it/it-technology-jobs" target="_blank" rel="noreferrer">Technology</a>),
                            key: 'te7',
                        }
                    ],
                },
            ],
        },
    ];

    const dropdownEmployer = [
        {
            key: '1',
            label: (
                <>Employer <span className="arrow-down-icon"></span></>
            ),
            children: [
                {
                    type: 'group',
                    label: 'Your Team',
                    children: [
                        {
                            label: <a href="https://www.hays.com.hk/it/register-a-vacancy" target="_blank" rel="noreferrer">Register a vacancy</a>,
                            key: 'yc1',
                        },
                        {
                            label: (<a href="https://hays.ramcoes.com/rvw/hub/index.html" target="_blank" rel="noreferrer">Timesheets</a>),
                            key: 'yc2',
                        },
                        {
                            label: (<a href="https://www.hays.com.hk/it/technology-salary-guide" target="_blank" rel="noreferrer">Benchmark salaries</a>),
                            key: 'yc3',
                        },
                    ],
                },
                {
                    type: 'group',
                    label: 'Technology Expertise',
                    children: [
                        {
                            label: (<a href="https://www.hays.com.hk/it/cloud-computing-recruitment" target="_blank" rel="noreferrer">Cloud Computing</a>),
                            key: 'te1',
                        },
                        {
                            label: (<a href="https://www.hays.com.hk/it/cyber-security-recruitment" target="_blank" rel="noreferrer">Cyber Security</a>),
                            key: 'te2',
                        },
                        {
                            label: (<a href="https://www.hays.com.hk/it/data-analytics-recruitment" target="_blank" rel="noreferrer">Data & Advanced Analytics</a>),
                            key: 'te3',
                        },
                        {
                            label: (<a href="https://www.hays.com.hk/it/it-infrastructure-recruitment" target="_blank" rel="noreferrer">Infrastructure</a>),
                            key: 'te4',
                        },
                        {
                            label: (<a href="https://www.hays.com.hk/it/it-projects-change-management-recruitment" target="_blank" rel="noreferrer">Projects & Change Management</a>),
                            key: 'te5',
                        },
                        {
                            label: (<a href="https://www.hays.com.hk/it/software-development-recruitment" target="_blank" rel="noreferrer">Software Development</a>),
                            key: 'te6',
                        }
                    ],
                },
                {
                    type: 'group',
                    label: 'Service',
                    children: [
                        {
                            label: <a href="https://www.hays.com.hk/it/it-recruitment" target="_blank" rel="noreferrer">Permanent & Contract Recruitment</a>,
                            key: 's1',
                        },
                        {
                            label: (<a href="https://www.hays.com.hk/it/msp-recruitment" target="_blank" rel="noreferrer">Managed Service Programmes (MSP)</a>),
                            key: 's2',
                        },
                        {
                            label: (<a href="https://www.hays.com.hk/it/rpo-recruitment" target="_blank" rel="noreferrer">Recruitment Process Outsourcing (RPO)</a>),
                            key: 's3',
                        },
                    ],
                }
            ],
        },
    ];

    return (
        <ul>
            <li className="first">
                <a href="https://www.hays.com.hk/it/about-us" target="_blank" rel="noreferrer">About Us</a>
            </li>
            <li className="first">
                <Menu mode="inline" items={dropdownJobseeker} />
            </li>
            <li className="first">
                <Menu mode="inline" items={dropdownEmployer} />
            </li>
            <li className="first">
                <a href="https://www.hays.com.hk/join-hays" target="_blank" rel="noreferrer">Join Hays</a>
            </li>
            <li className="first">
                <a href="https://m.hays.com.hk/it/all-offices" target="_blank" rel="noreferrer">Get in Touch</a>
            </li>
            <li className="first">
                <a href="https://www.haysplc.com/investors" target="_blank" rel="noreferrer">Investor</a>
            </li>
        </ul>
    )
}

export default NavHkMobile;