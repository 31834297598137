import React from 'react';
import {Dropdown} from "antd";
import JobseekerCn from "../links/JobseekerCn";
import EmployerCn from "../links/EmployerCn";

function NavCn() {
    return (
        <ul>
            <li className="first">
                <a href="https://www.hays-china.cn/web/hays-cn-technology/about-us" target="_blank" rel="noreferrer">关于我们</a>
            </li>
            <li className="first">
                <Dropdown overlay={<JobseekerCn/>} overlayClassName="megaMenuOverlay" trigger={['click']} getPopupContainer={() => document.getElementById('nav-main')}>
                    <button type="link" onClick={(e) => e.preventDefault()}>
                        求职者 <span className="arrow-down-icon"></span>
                    </button>
                </Dropdown>
            </li>
            <li className="first">
                <Dropdown overlay={<EmployerCn/>} overlayClassName="megaMenuOverlay" trigger={['click']} getPopupContainer={() => document.getElementById('nav-main')}>
                    <button type="link" onClick={(e) => e.preventDefault()}>
                        雇主 <span className="arrow-down-icon"></span>
                    </button>
                </Dropdown>
            </li>
            <li className="first">
                <a href="https://www.hays-china.cn/join-hays" target="_blank" rel="noreferrer">加入瀚纳仕</a>
            </li>
            <li className="first">
                <a href="https://m.hays-china.cn/it/office-locator" target="_blank" rel="noreferrer">取得联系</a>
            </li>
            <li className="first">
                <a href="https://www.haysplc.com/investors" target="_blank" rel="noreferrer">投資者</a>
            </li>
        </ul>
    )
}

export default NavCn;