import React from 'react';
import {Dropdown} from "antd";
import JobseekerJp from "../links/JobseekerJp";
import EmployerJp from "../links/EmployerJp";

function NavJp() {
    return (
        <ul>
            <li className="first">
                <a href="https://www.hays.co.jp/it/about-us" target="_blank" rel="noreferrer">IT転職・採用支援について</a>
            </li>
            <li className="first">
                <Dropdown overlay={<JobseekerJp/>} overlayClassName="megaMenuOverlay" trigger={['click']} getPopupContainer={() => document.getElementById('nav-main')}>
                    <button type="link" onClick={(e) => e.preventDefault()}>
                        求職者様 <span className="arrow-down-icon"></span>
                    </button>
                </Dropdown>
            </li>
            <li className="first">
                <Dropdown overlay={<EmployerJp/>} overlayClassName="megaMenuOverlay" trigger={['click']} getPopupContainer={() => document.getElementById('nav-main')}>
                    <button type="link" onClick={(e) => e.preventDefault()}>
                        採用企業様 <span className="arrow-down-icon"></span>
                    </button>
                </Dropdown>
            </li>
            <li className="first">
                <a href="https://www.hays.co.jp/join-hays" target="_blank" rel="noreferrer">自社採用</a>
            </li>
            <li className="first">
                <a href="https://m.hays.co.jp/it/office-locator" target="_blank" rel="noreferrer">お問い合わせ</a>
            </li>
            <li className="first">
                <a href="https://www.haysplc.com/investors" target="_blank" rel="noreferrer">投資家</a>
            </li>
        </ul>
    )
}

export default NavJp;