import React, {Fragment, useContext} from "react";
import "./App.scss";
import {PAGE_END, PAGE_INTRO, PAGE_QUESTION, PAGE_START} from "./app/types";
import PageQuestion from "./components/PageQuestion/PageQuestion";
import {Content} from "antd/es/layout/layout";
import {Layout} from "antd";

import PageEnd from "./components/PageEnd/PageEnd";
import HaysHeader from "./components/Common/Header/HaysHeader";
import HaysFooter from "./components/Common/Footer/HaysFooter";
import {Context} from "./app/store";
import PageIntro from "./components/PageIntro/PageIntro";
import PageStart from "./components/PageStart/PageStart";
import useWindowSize from "./hooks/UseWindowSize";
import HaysHeaderMobile from "./components/Common/HeaderMobile/HaysHeaderMobile";
import {Helmet} from "react-helmet";

function AppRouter() {
    const state = useContext(Context)[0];
    const size = useWindowSize();
    const [globalState, dispatch] = useContext(Context);
    let market = window.location.pathname.replaceAll("/", "");
    let hostname = window.location.hostname;
    let gtmId;
    if(market && (market === "sg" || market === "my" || market === "jp" || market === "cn" || market === "hk")){
    }else{
        market = globalState.location;
    }

    if(hostname.includes(".co.jp")){
        market = "jp";
    }
    if(hostname.includes(".cn")){
        market = "cn";
    }
    if(hostname.includes(".com.sg")){
        market = "sg";
    }
    if(hostname.includes(".com.my")){
        market = "my";
    }
    if(hostname.includes(".com.hk")){
        market = "hk";
    }

    let defLang = "en";
    switch (market) {
        case "sg":
            gtmId = process.env.REACT_APP_GTM_SG;
            defLang = "en";
            break;
        case "my":
            gtmId = process.env.REACT_APP_GTM_MY;
            defLang = "en";
            break;
        case "hk":
            gtmId = process.env.REACT_APP_GTM_HK;
            defLang = "en";
            break;
        case "cn":
            gtmId = process.env.REACT_APP_GTM_CN;
            defLang = "cn";
            break;
        case "jp":
            gtmId = process.env.REACT_APP_GTM_JP;
            defLang = "jp";
            break;
        default:
            gtmId = process.env.REACT_APP_GTM_SG;
            defLang = "en";
            break;
    }

    return (
        <Fragment>
            { (process.env.REACT_APP_ENV === "production" || process.env.REACT_APP_ENV === "staging") &&
                <Helmet>
                    <script>
                    {`
                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer',  '${gtmId}');
                    `}
                    </script>
                    <noscript>{`
                        <noscript><iframe src=https://www.googletagmanager.com/ns.html?id=${gtmId}
                      height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>
                </Helmet>
            }

            <Layout>

                {size.width >= 768 && <HaysHeader />}
                {size.width < 768 && <HaysHeaderMobile />}

                <Content className={"content-wrapper"}>
                    {state.page === PAGE_INTRO &&
                        <Fragment>
                            <PageIntro/>
                        </Fragment>
                    }
                    {state.page === PAGE_START &&
                        <Fragment>
                            <PageStart/>
                        </Fragment>
                    }
                    {state.page === PAGE_QUESTION &&
                        <Fragment>
                            <PageQuestion/>
                        </Fragment>
                    }
                    {state.page === PAGE_END &&
                        <Fragment>
                            <PageEnd/>
                        </Fragment>
                    }
                </Content>
                <HaysFooter />
            </Layout>
        </Fragment>
    );
}

export default AppRouter;
