import React from 'react';
import {Col, Row} from "antd";

function ExpertsCn() {
    return (
        <>
            <h2>科技领域招聘专家</h2>
            <Row>
                <Col xs={12} lg={6}>
                    <h4>热门链接</h4>
                    <ul>
                        <li><a href="https://m.hays-china.cn/it/search" target="_blank" rel="noreferrer">寻找职位</a></li>
                        <li><a href="https://m.hays-china.cn/it/speculativeCV" target="_blank" rel="noreferrer">提交简历</a></li>
                        <li><a href="https://www.hays-china.cn/it/technology-salary-guide" target="_blank" rel="noreferrer">科技薪酬指南</a></li>
                        <li><a href="https://www.hays-china.cn/it/register-a-vacancy" target="_blank" rel="noreferrer">登记职位空缺</a></li>
                        <li><a href="https://m.hays-china.cn/it/office-locator" target="_blank" rel="noreferrer">联系我们</a></li>
                    </ul>
                </Col>
                <Col xs={12} lg={6}>
                    <h4>探索瀚纳仕中国</h4>
                    <ul>
                        <li><a href="https://www.hays-china.cn/" target="_blank" rel="noreferrer">hays-china.cn</a></li>
                        <li><a href="https://www.hays-china.cn/advice-services" target="_blank" rel="noreferrer">职场建议</a></li>
                        <li><a href="https://m.hays-china.cn/search" target="_blank" rel="noreferrer">寻找所有职位</a></li>
                        <li><a href="https://www.hays-china.cn/media-centre" target="_blank" rel="noreferrer">媒体中心</a></li>
                    </ul>
                </Col>
                <Col xs={12} lg={6}>
                    <h4>信息</h4>
                    <ul>
                        <li><a href="https://www.hays-china.cn/it/offices" target="_blank" rel="noreferrer">各地办事处</a></li>
                        <li><a href="https://www.hays-china.cn/accessibility-guidelines" target="_blank" rel="noreferrer">可兼容性</a></li>
                        <li><a href="https://www.hays-china.cn/it/sitemap" target="_blank" rel="noreferrer">网站地图</a></li>
                        <li><a href="https://www.hays-china.cn/it/about-us" target="_blank" rel="noreferrer">关于我们</a></li>
                        <li><a href="https://www.hays-china.cn/scam-alert" target="_blank" rel="noreferrer">诈骗警告</a></li>
                        <li><a href="https://www.hays-china.cn/site-help" target="_blank" rel="noreferrer">帮助</a></li>
                    </ul>
                </Col>
                <Col xs={12} lg={6}>
                    <h4>政策</h4>
                    <ul>
                        <li><a href="https://www.hays-china.cn/terms-conditions" target="_blank" rel="noreferrer">条款及条件</a></li>
                        <li><a href="https://www.hays-china.cn/privacy-policy" target="_blank" rel="noreferrer">隐私政策</a></li>
                        <li><a href="https://www.hays-china.cn/disclaimer" target="_blank" rel="noreferrer">免责声明</a></li>
                    </ul>
                </Col>
            </Row>
        </>
    )
}

export default ExpertsCn;