import React from 'react';
import "./AnimatedChart.scss";
import "./AnimatedChartCn.scss";

function AnimatedChartCn() {
    return (
        <>
            <div className="pie pie-bottom-cn"></div>
            <div className="pie pie-left-cn"></div>
            <div className="pie pie-right-cn"></div>
        </>
    );
}
export default AnimatedChartCn;