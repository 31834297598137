import React from 'react';
import {Menu} from "antd";

function NavCnMobile() {
    const dropdownJobseeker = [
        {
            key: '1',
            label: (
                <>求职者 <span className="arrow-down-icon"></span></>
            ),
            children: [
                {
                    type: 'group',
                    label: '你的职业发展',
                    children: [
                        {
                            label: <a href="https://m.hays-china.cn/it/search" target="_blank" rel="noreferrer">寻找信息技术类职位</a>,
                            key: 'yc1',
                        },
                        {
                            label: (<a href="https://m.hays-china.cn/it/speculativeCV" target="_blank" rel="noreferrer">提交我的简历</a>),
                            key: 'yc2',
                        },
                        {
                            label: (<a href="https://www.hays-china.cn/web/hays-cn-technology/my-salary" target="_blank" rel="noreferrer">我的薪资</a>),
                            key: 'yc4',
                        },
                    ],
                },
                {
                    type: 'group',
                    label: '科技专业知识',
                    children: [
                        {
                            label: (<a href="https://www.hays-china.cn/web/hays-cn-technology/cloud-computing-jobs" target="_blank" rel="noreferrer">云计算</a>),
                            key: 'te1',
                        },
                        {
                            label: (<a href="https://www.hays-china.cn/web/hays-cn-technology/cyber-security-jobs" target="_blank" rel="noreferrer">网络安全</a>),
                            key: 'te2',
                        },
                        {
                            label: (<a href="https://www.hays-china.cn/web/hays-cn-technology/data-analytics-jobs" target="_blank" rel="noreferrer">数据分析与建模</a>),
                            key: 'te3',
                        },
                        {
                            label: (<a href="https://www.hays-china.cn/web/hays-cn-technology/it-infrastructure-jobs" target="_blank" rel="noreferrer">基础设施</a>),
                            key: 'te4',
                        },
                        {
                            label: (<a href="https://www.hays-china.cn/web/hays-cn-technology/it-projects-change-management-jobs" target="_blank" rel="noreferrer">项目&变革管理</a>),
                            key: 'te5',
                        },
                        {
                            label: (<a href="https://www.hays-china.cn/web/hays-cn-technology/software-development-jobs" target="_blank" rel="noreferrer">软件开发</a>),
                            key: 'te6',
                        },
                        {
                            label: (<a href="https://www.hays-china.cn/web/hays-cn-technology/it-technology-jobs" target="_blank" rel="noreferrer">科技</a>),
                            key: 'te7',
                        }
                    ],
                },
            ],
        },
    ];

    const dropdownEmployer = [
        {
            key: '1',
            label: (
                <>雇主 <span className="arrow-down-icon"></span></>
            ),
            children: [
                {
                    type: 'group',
                    label: '你的团队',
                    children: [
                        {
                            label: <a href="https://www.hays-china.cn/web/hays-cn-technology/register-a-vacancy" target="_blank" rel="noreferrer">登记职位空缺</a>,
                            key: 'yc1',
                        },
                        {
                            label: (<a href="https://www.hays-china.cn/web/hays-cn-technology/benchmark-salaries1" target="_blank" rel="noreferrer">薪酬基准</a>),
                            key: 'yc3',
                        },
                    ],
                },
                {
                    type: 'group',
                    label: '科技专业知识',
                    children: [
                        {
                            label: (<a href="https://www.hays-china.cn/web/hays-cn-technology/cloud-computing-recruitment" target="_blank" rel="noreferrer">云计算</a>),
                            key: 'te1',
                        },
                        {
                            label: (<a href="https://www.hays-china.cn/web/hays-cn-technology/cyber-security-recruitment" target="_blank" rel="noreferrer">网络安全</a>),
                            key: 'te2',
                        },
                        {
                            label: (<a href="https://www.hays-china.cn/web/hays-cn-technology/data-analytics-recruitment" target="_blank" rel="noreferrer">数据分析与建模</a>),
                            key: 'te3',
                        },
                        {
                            label: (<a href="https://www.hays-china.cn/web/hays-cn-technology/it-infrastructure-recruitment" target="_blank" rel="noreferrer">基础设施</a>),
                            key: 'te4',
                        },
                        {
                            label: (<a href="https://www.hays-china.cn/web/hays-cn-technology/it-projects-change-management-recruitment" target="_blank" rel="noreferrer">项目&变革管理</a>),
                            key: 'te5',
                        },
                        {
                            label: (<a href="https://www.hays-china.cn/web/hays-cn-technology/software-development-recruitment" target="_blank" rel="noreferrer">软件开发</a>),
                            key: 'te6',
                        }
                    ],
                },
                {
                    type: 'group',
                    label: '服务',
                    children: [
                        {
                            label: <a href="https://www.hays-china.cn/web/hays-cn-technology/it-recruitment" target="_blank" rel="noreferrer">长期职位招聘</a>,
                            key: 's1',
                        },
                        {
                            label: (<a href="https://www.hays-china.cn/web/hays-cn-technology/msp-recruitment" target="_blank" rel="noreferrer">管理服务方案</a>),
                            key: 's2',
                        },
                        {
                            label: (<a href="https://www.hays-china.cn/web/hays-cn-technology/rpo-recruitment" target="_blank" rel="noreferrer">招聘流程外包</a>),
                            key: 's3',
                        },
                    ],
                }
            ],
        },
    ];

    return (
        <ul>
            <li className="first">
                <a href="https://www.hays-china.cn/web/hays-cn-technology/about-us" target="_blank" rel="noreferrer">关于我们</a>
            </li>
            <li className="first">
                <Menu mode="inline" items={dropdownJobseeker} />
            </li>
            <li className="first">
                <Menu mode="inline" items={dropdownEmployer} />
            </li>
            <li className="first">
                <a href="https://www.hays-china.cn/join-hays" target="_blank" rel="noreferrer">加入瀚纳仕</a>
            </li>
            <li className="first">
                <a href="https://m.hays-china.cn/it/office-locator" target="_blank" rel="noreferrer">取得联系</a>
            </li>
            <li className="first">
                <a href="https://www.haysplc.com/investors" target="_blank" rel="noreferrer">投資者</a>
            </li>
        </ul>
    )
}

export default NavCnMobile;