import React from 'react';
import {useTranslation} from "react-i18next";
import Percentage from "./Percentage";

function MatchDesc({ order, type, percentage }) {
    const { t } = useTranslation();
    return (
        <>
            {order === 1 &&
                <>
                    <Percentage percentage={percentage} />
                    <p>{type === 'employer' ? t('question.resume.employer.desc') : t('question.resume.employee.desc')}</p>
                </>
            }
            {order === 2 &&
                <>
                    <Percentage percentage={percentage} />
                    <p>{type === 'employer' ? t('question.workplace.employer.desc') : t('question.workplace.employee.desc')}</p>
                </>
            }
            {order === 3 &&
                <>
                    <Percentage percentage={percentage} />
                    <p>{type === 'employer' ? t('question.salary.employer.desc') : t('question.salary.employee.desc')}</p>
                </>
            }
            {order === 4 &&
                <>
                    <Percentage percentage={percentage} />
                    <p>{type === 'employer' ? t('question.culture.employer.desc') : t('question.culture.employee.desc')}</p>
                </>
            }
            {order === 5 &&
                <>
                    <Percentage percentage={percentage} />
                    <p>{type === 'employer' ? t('question.tech.employer.desc') : t('question.tech.employee.desc')}</p>
                </>
            }
        </>
    )
}

export default MatchDesc;
