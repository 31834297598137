import React from 'react';
import "./AnimatedChart.scss";
function AnimatedChart() {
    return (
        <>
            <div className="pie pie-bottom"></div>
            <div className="pie pie-left"></div>
            <div className="pie pie-right"></div>
        </>
    );
}
export default AnimatedChart;