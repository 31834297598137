import {
    GOTO_PAGE, HIDE_WECHAT_MODAL,
    NEXT_QUESTION,
    PAGE_INTRO,
    RESTART,
    SET_DEBUG,
    SET_LANGUAGES, SET_LOC,
    SET_QUESTIONS, SHOW_WECHAT_MODAL
} from "./types";


let envState = {
    page: PAGE_INTRO,
    sessionId: '',
    question: 0,
    questions: [],
    languages: [],
    location: process.env.REACT_APP_DEFAULT_MARKET,
    type: '',
    progress: 0,
    steps: 7,
    answertype: "",
    userChangedLang: false,
    debug: false,
    showModal: false
}

export const initialState = envState;

const UserReducer = (state = initialState, action) => {
    switch (action.type) {

        case RESTART:
            state = initialState;
            return state;
        case SET_LANGUAGES:
            return{
                ...state,
                userChangedLang: true
            };
        case SET_DEBUG:
            return{
                ...state,
                debug: true
            };
        case SET_LOC:
            return{
                ...state,
                location: action.payload.location
            };
        case SHOW_WECHAT_MODAL:
            return{
                ...state,
                showModal: true
            };
        case HIDE_WECHAT_MODAL:
            return{
                ...state,
                showModal: false
            };
        case GOTO_PAGE:
            return {
                ...state,
                page: action.payload.page,
                sessionId: action.payload.sessionId ? action.payload.sessionId : state.sessionId,
                type: action.payload.type ? action.payload.type : state.type,
                location: action.payload.location ? action.payload.location : state.location,
                progress: action.payload.progress ? (action.payload.progress / state.steps * 100) : state.progress,
                answertype: action.payload.answertype ? action.payload.answertype : state.answertype
            };
        case NEXT_QUESTION:
            return {
                ...state,
                progress: (1 + state.question + 1) / state.steps * 100,
                question: (state.question+1),
            };
        case SET_QUESTIONS:
            return{
                ...state,
                questions: action.payload.questions,
            };
        default:
            return state;
    }
};

export default UserReducer;