import React, {useContext} from 'react';
import {Context} from "../../../app/store";

function ProgressBar() {
    const [globalState] = useContext(Context);
    return (<>
        {
            globalState.progress > 0 &&
                <div className={"gray-progress-background"}>
                    <div style={{width: globalState.progress + "%"}} className={'blue-progress'}>
                        <div className={"arrow-down"}></div>
                    </div>
                </div>
        }
        </>
    )
}
export default ProgressBar;
